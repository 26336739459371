// @ts-check
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import isEmail from 'validator/lib/isEmail';
import { captureError } from '../api/capture-error';
import { submitPaymentInfo } from '../api/submit-payment-info';
import { AppContext, TYPES } from '../contexts/app-context';
import { PurchaseFormItemEmail } from './purchase-form-item-email';
import { PurchaseFormItemInfo } from './purchase-form-item-info';
import { PurchaseFormItemPay } from './purchase-form-item-pay';
import { PurchaseFormItemPrice } from './purchase-form-item-price';

const styles = StyleSheet.create({
  form: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
});

export function PurchaseFormSubmit(props) {
  // Context
  const context = React.useContext(AppContext);
  // Props
  const { apiLoginKey, publicClientKey } = props;
  // State
  const [email, setEmail] = React.useState('');
  const [cardData, setCardData] = React.useState({});
  const [submitting, setSubmitting] = React.useState(false);
  // Variables
  const expDate = get(cardData, 'encryptedCardData.expDate', '');
  const validEmail = isEmail(email);
  const readyToPay = Boolean(validEmail && expDate);

  const displayError = type => {
    const status = type.toString().toLowerCase();
    if (status === 'payment-failure') {
      context.dispatch({
        payload: {
          buttons: [
            {
              onPress: () => {
                setCardData({});
                context.dispatch({ type: TYPES.ERR_RESET });
              },
              text: 'Try again',
            },
          ],
          render: true,
          type: 'payment-failure',
        },
        type: TYPES.ERR_CREATE,
      });
    } else {
      context.dispatch({
        payload: {
          buttons: [
            {
              onPress: () => {
                props.history.push('/menu');
                context.dispatch({ type: TYPES.ERR_RESET });
              },
              text: 'Return to menu',
            },
          ],
          render: true,
          type: 'error',
        },
        type: TYPES.ERR_CREATE,
      });
    }
  };

  const handlePress = async () => {
    try {
      setSubmitting(true);
      const { response, results } = await submitPaymentInfo({
        cardData,
        email,
      });
      if (response.ok) {
        const { assessmentCode } = results;
        if (assessmentCode) {
          props.setCode(assessmentCode);
          // ScreenPurchase will now render PurchaseComplete
          props.setViewing('complete');
        } else {
          const message =
            'Response from server did not include assessmentCode and may have charged the users card';
          throw new Error(
            `${response.status} ${response.statusText}: ${message}`
          );
        }
      } else {
        // response.ok === false
        let message = `${response.status} ${response.statusText}`;
        if (results['hydra:description']) {
          message = results['hydra:description'];
        }
        throw new Error(message);
      }
    } catch (error) {
      if (error.message.toLowerCase().includes('payment-failure')) {
        displayError('payment-failure');
      } else {
        displayError('error');
      }
      captureError({ data: { cardData, email, error } });
      setSubmitting(false);
    }
  };

  const handleResponse = response => {
    const resultCode = get(response, 'messages.resultCode', '');
    if (resultCode === 'Ok') {
      setCardData(response);
    } else {
      captureError({ data: { email, response } });
      displayError();
    }
  };

  return (
    <View style={styles.form}>
      <View style={{ flex: 1, marginHorizontal: 16, maxWidth: 400 }}>
        <PurchaseFormItemPrice />

        <PurchaseFormItemEmail
          email={email}
          setEmail={setEmail}
          validEmail={validEmail}
        />

        <PurchaseFormItemInfo
          apiLoginKey={apiLoginKey}
          cardData={cardData}
          handleResponse={handleResponse}
          publicClientKey={publicClientKey}
          validEmail={validEmail}
        />

        <PurchaseFormItemPay
          handlePress={handlePress}
          readyToPay={readyToPay}
          submitting={submitting}
        />
      </View>
    </View>
  );
}

PurchaseFormSubmit.propTypes = {
  apiLoginKey: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  publicClientKey: PropTypes.string.isRequired,
  setCode: PropTypes.func.isRequired,
  setViewing: PropTypes.func.isRequired,
};
