// @ts-check
import { checkParams } from '../utils/check-params';
import { captureError } from './capture-error';

/**
 * @typedef {Object} Values
 * @property {Response} response
 * @property {Object} results
 *
 * @param {Object} params
 * @param {string} params.email - Email address of the user who is purchasing the assessment
 * @param {Object} params.cardData - Data returned from the Authorize payment information form
 *
 * @returns {Promise<Values>}
 */
export async function submitPaymentInfo(params) {
  checkParams({ functionName: 'submitPaymentInfo', functionParams: params });

  const input = `${process.env.REACT_APP_RESOURCE_URL}/assessment/purchase`;

  try {
    const {
      customerInformation,
      encryptedCardData,
      opaqueData,
    } = params.cardData;

    const data = {
      creditCardBin: encryptedCardData.bin,
      creditCardExpDate: encryptedCardData.expDate,
      creditCardLastFour: encryptedCardData.cardNumber,
      dataDescriptor: opaqueData.dataDescriptor,
      dataValue: opaqueData.dataValue,
      purchaserEmail: params.email,
      purchaserFirstName: customerInformation.firstName,
      purchaserLastName: customerInformation.lastName,
    };

    const response = await fetch(input, {
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    });
    const results = await response.json();
    return Promise.resolve({ response, results });
  } catch (error) {
    captureError({ data: { error, input, params } });
    throw new Error(error);
  }
}
