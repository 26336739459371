// @ts-check
import PropTypes from 'prop-types';
import React from 'react';
import { AuthorizeAccept } from './authorize-accept';

const styles = {
  button: {
    backgroundColor: '#16B9FF',
    border: 'none',
    borderRadius: 2,
    color: '#fff',
    cursor: 'pointer',
    fontFamily: 'Roboto',
    fontSize: 18,
    padding: 8,
    width: '100%',
  },
};

export function AuthorizeForm(props) {
  React.useEffect(() => {
    // @ts-ignore
    window.responseHandler = props.responseHandler;
  }, [props.responseHandler]);

  return (
    <React.Fragment>
      {!props.disabled && <AuthorizeAccept />}

      <form id="paymentForm" method="POST">
        <input id="dataValue" name="dataValue" type="hidden" />
        <input id="dataDescriptor" name="dataDescriptor" type="hidden" />
        <button
          className="AcceptUI"
          data-acceptuiformbtntxt="Submit"
          data-acceptuiformheadertxt="Card Information"
          data-apiloginid={props.apiLoginKey}
          data-billingaddressoptions='{"show":true, "required":false}'
          data-clientkey={props.publicClientKey}
          data-paymentoptions='{"showCreditCard": true}'
          data-responsehandler="responseHandler"
          disabled={props.disabled}
          style={styles.button}
          type="button"
        >
          Open payment information form
        </button>
      </form>
    </React.Fragment>
  );
}

AuthorizeForm.propTypes = {
  apiLoginKey: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  publicClientKey: PropTypes.string.isRequired,
  responseHandler: PropTypes.func.isRequired,
};
