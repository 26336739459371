import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import CreditCard from 'react-feather/dist/icons/credit-card';
import { StyleSheet, Text, View } from 'react-native';
import { animated, useSpring } from 'react-spring/native';
import { AuthorizeForm } from './authorize-form';

const styles = StyleSheet.create({
  cardDate: {
    fontFamily: 'Roboto',
    fontSize: 18,
    fontWeight: 'bold',
  },
  cardDetails: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: 8,
  },
  cardNumber: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    marginLeft: 8,
    textTransform: 'lowercase',
  },
  container: {
    backgroundColor: 'rgba(255, 255, 255, 0.90)',
    padding: 8,
  },
  title: {
    color: '#222',
    fontFamily: 'Roboto',
    fontSize: 10,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
});

const Animated = {
  View: animated(View),
};

export function PurchaseFormItemInfo(props) {
  // Props
  const {
    apiLoginKey,
    cardData,
    handleResponse,
    publicClientKey,
    validEmail,
  } = props;
  // Variables
  const expDate = get(cardData, 'encryptedCardData.expDate', '');
  const cardNumber = get(cardData, 'encryptedCardData.cardNumber', '');
  const values = useSpring({
    from: { opacity: 0.2 },
    to: { opacity: validEmail ? 1 : 0.2 },
  });

  return (
    <Animated.View style={{ opacity: values.opacity }}>
      <View style={styles.container}>
        <Text style={styles.title}>Payment Information</Text>

        {expDate ? (
          <View style={styles.cardDetails}>
            <CreditCard />

            <Text style={styles.cardNumber}>{cardNumber}</Text>

            <View style={{ flex: 1 }} />

            <Text style={styles.cardDate}>{expDate}</Text>
          </View>
        ) : (
          <AuthorizeForm
            apiLoginKey={apiLoginKey}
            disabled={!validEmail}
            publicClientKey={publicClientKey}
            responseHandler={handleResponse}
          />
        )}
      </View>
    </Animated.View>
  );
}

PurchaseFormItemInfo.propTypes = {
  apiLoginKey: PropTypes.string.isRequired,
  cardData: PropTypes.object.isRequired,
  handleResponse: PropTypes.func.isRequired,
  publicClientKey: PropTypes.string.isRequired,
  validEmail: PropTypes.bool.isRequired,
};
