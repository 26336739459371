// @ts-check
import React from 'react';
import ArrowLeft from 'react-feather/dist/icons/arrow-left';
import { StyleSheet, Text, TouchableOpacity } from 'react-native';
import { Link } from 'react-router-dom';

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    flexDirection: 'row',
    height: 32,
  },
  buttonText: {
    color: '#fff',
    fontFamily: 'Roboto',
    marginLeft: 4,
  },
});

export function MenuButton(props) {
  return (
    <Link style={{ textDecorationLine: 'none' }} to="/menu">
      <TouchableOpacity accessible={false} style={styles.button}>
        <ArrowLeft color="#fff" size={20} />
        <Text style={styles.buttonText}>Menu</Text>
      </TouchableOpacity>
    </Link>
  );
}

MenuButton.propTypes = {};
