// @ts-check

/**
 * @param {Object} params
 * @param {string} params.functionName
 * @param {Object} params.functionParams
 */
export function checkParams(params) {
  const { functionName, functionParams } = params;

  if (typeof params !== 'object') {
    const message = 'First argument to checkParams({}) should be an object';
    throw new Error(message);
  } else if (typeof functionParams !== 'object') {
    const message = `First argument to ${functionName}({}) should be an object`;
    throw new Error(message);
  }
}
