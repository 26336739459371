// @ts-check
import profiles from '../../data/profiles.json';

/**
 * Get an array of Traits from the users assessment results
 *
 * @typedef {Object} Trait
 * @property {Object} profile
 * @property {string} profile.intro
 * @property {string} profile.strength
 * @property {string} profile.weakness
 * @property {string} trait
 * @property {string} type
 *
 * @typedef {Object} Score
 * @property {string} char - a or b or c or d
 * @property {number} num - The total of selections for the letter
 *
 * @param {Object} params
 * @param {number} params.questionsCount - Amount of questions in assessment
 * @param {Score[]} params.results - [ { char: 'a', num: 110 }, ... ]
 *
 * @returns {{ traits: Trait[] }} { traits: [ { profile: [Object], ... }, ... ] }
 *
 * @example
 * const { traits } = getTraits({
 *   questionsCount: 42,
 *   results: [{ char: "b", num: 133 }, { char: "d", num: 113 }]
 * });
 */
export function getTraits({ questionsCount, results }) {
  // The array of traits to be returned
  const traits = [];

  const threshold = questionsCount * 3;
  // Returns true if the value is less than the threshold
  const ltThreshold = value => value < threshold;
  // Returns true if the value is greater than or equal to the threshold
  const gteThreshold = value => value >= threshold;

  // There are 16 possible traits
  const options = [
    {
      params: {
        oppositeValues: { a: ltThreshold },
        valuesOne: { a: ltThreshold, d: ltThreshold },
        valuesTwo: { a: ltThreshold, d: ltThreshold },
      },
      trait: 'DIRECTOR',
    },
    {
      params: {
        oppositeValues: {},
        valuesOne: { a: gteThreshold },
        valuesTwo: { a: gteThreshold, d: ltThreshold },
      },
      trait: 'DRIVER',
    },
    {
      params: {
        oppositeValues: {},
        valuesOne: { d: gteThreshold },
        valuesTwo: { a: ltThreshold, d: gteThreshold },
      },
      trait: 'SOLVER',
    },
    {
      params: {
        oppositeValues: { a: gteThreshold },
        valuesOne: {},
        valuesTwo: { a: gteThreshold, d: gteThreshold },
      },
      trait: 'ACHIEVER',
    },
    {
      params: {
        oppositeValues: { b: ltThreshold },
        valuesOne: { a: ltThreshold, b: ltThreshold },
        valuesTwo: { a: ltThreshold, b: ltThreshold },
      },
      trait: 'EMPHASIZER',
    },
    {
      params: {
        oppositeValues: {},
        valuesOne: { a: gteThreshold },
        valuesTwo: { a: gteThreshold, b: ltThreshold },
      },
      trait: 'ENERGIZER',
    },
    {
      params: {
        oppositeValues: {},
        valuesOne: { b: gteThreshold },
        valuesTwo: { a: ltThreshold, b: gteThreshold },
      },
      trait: 'INFLUENCER',
    },
    {
      params: {
        oppositeValues: { b: gteThreshold },
        valuesOne: {},
        valuesTwo: { a: gteThreshold, b: gteThreshold },
      },
      trait: 'INITIATOR',
    },
    {
      params: {
        oppositeValues: { c: ltThreshold },
        valuesOne: { b: ltThreshold, c: ltThreshold },
        valuesTwo: { b: ltThreshold, c: ltThreshold },
      },
      trait: 'ACCOMMODATOR',
    },
    {
      params: {
        oppositeValues: {},
        valuesOne: { b: gteThreshold },
        valuesTwo: { b: gteThreshold, c: ltThreshold },
      },
      trait: 'INSPIRER',
    },
    {
      params: {
        oppositeValues: {},
        valuesOne: { c: gteThreshold },
        valuesTwo: { b: ltThreshold, c: gteThreshold },
      },
      trait: 'NURTURER',
    },
    {
      params: {
        oppositeValues: { c: gteThreshold },
        valuesOne: {},
        valuesTwo: { b: gteThreshold, c: gteThreshold },
      },
      trait: 'RELATOR',
    },
    {
      params: {
        oppositeValues: { d: ltThreshold },
        valuesOne: { c: ltThreshold, d: ltThreshold },
        valuesTwo: { c: ltThreshold, d: ltThreshold },
      },
      trait: 'STABILIZER',
    },
    {
      params: {
        oppositeValues: {},
        valuesOne: { c: gteThreshold },
        valuesTwo: { c: gteThreshold, d: ltThreshold },
      },
      trait: 'AFFIRMER',
    },
    {
      params: {
        oppositeValues: {},
        valuesOne: { d: gteThreshold },
        valuesTwo: { c: ltThreshold, d: gteThreshold },
      },
      trait: 'INVESTIGATOR',
    },
    {
      params: {
        oppositeValues: { d: gteThreshold },
        valuesOne: {},
        valuesTwo: { c: gteThreshold, d: gteThreshold },
      },
      trait: 'ANALYZER',
    },
  ];

  if (results.length === 1) {
    // A or B or C or D
    // Two traits will be added to the traits array
    options.forEach(option => {
      // Evaluate each option based on params.valueOne
      const { valuesOne } = option.params;

      const { char, num } = results[0];
      if (valuesOne.hasOwnProperty(char)) {
        if (valuesOne[char](num)) {
          // The profile for this trait
          const profile = profiles[option.trait.toLowerCase()];
          traits.push({ profile, trait: option.trait, type: 'trait' });
        }
      }
    });
  } else if (results.length === 2) {
    const chars = results.map(value => value.char);
    const isOpposite =
      (chars.includes('a') && chars.includes('c')) ||
      (chars.includes('b') && chars.includes('d'));

    if (isOpposite) {
      // AC or BD
      // Two traits will be added to the traits array
      options.forEach(option => {
        // Evaluate each option based on params.oppositeValues
        const { oppositeValues } = option.params;

        results.forEach(value => {
          if (oppositeValues.hasOwnProperty(value.char)) {
            if (oppositeValues[value.char](value.num)) {
              // The profile for this trait
              const profile = profiles[option.trait.toLowerCase()];
              traits.push({ profile, trait: option.trait, type: 'trait' });
            }
          }
        });
      });
    } else {
      // AB or BC or CD or DA
      // One trait will be added to the traits array
      options.forEach(option => {
        // Evaluate each option based on params.valuesTwo
        const { valuesTwo } = option.params;

        const hasCharOne = valuesTwo.hasOwnProperty(results[0].char);
        const hasCharTwo = valuesTwo.hasOwnProperty(results[1].char);
        if (hasCharOne && hasCharTwo) {
          const checkScoreOne = valuesTwo[results[0].char](results[0].num);
          const checkScoreTwo = valuesTwo[results[1].char](results[1].num);
          if (checkScoreOne && checkScoreTwo) {
            // The profile for this trait
            const profile = profiles[option.trait.toLowerCase()];
            traits.push({ profile, trait: option.trait, type: 'trait' });
          }
        }
      });
    }
  }

  return { traits };
}
