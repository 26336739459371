// @ts-check
import shortid from 'shortid';
import { checkParams } from './check-params';

/**
 * @typedef {Object} Values
 * @property {boolean} isValidCode
 *
 * @param {Object} params
 * @param {string} params.code - The code being used for the assessment
 *
 * @returns {Values}
 */
export function validCode(params) {
  checkParams({ functionName: 'validCode', functionParams: params });
  const isValidCode = shortid.isValid(params.code);
  return { isValidCode };
}
