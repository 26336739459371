// @ts-check
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React from 'react';
import XIcon from 'react-feather/dist/icons/x';
import { TouchableOpacity, View } from 'react-native';
import { MenuButton } from './menu-button';
import { NavBar } from './nav-bar';
import { PurchaseComplete } from './purchase-complete';
import { PurchaseFormLoad } from './purchase-form-load';
import { PurchaseIntro } from './purchase-intro';

export function ScreenPurchase(props) {
  // Props
  const { history } = props;
  // State
  const [viewing, setViewing] = React.useState('intro');
  const [code, setCode] = React.useState('');

  const renderViewing = () => {
    switch (viewing) {
      case 'intro':
        return <PurchaseIntro setViewing={setViewing} />;
      case 'form':
        return (
          <PurchaseFormLoad
            history={history}
            setCode={setCode}
            setViewing={setViewing}
          />
        );
      case 'complete':
        return <PurchaseComplete {...props} code={code} />;
      default:
        return <PurchaseIntro setViewing={setViewing} />;
    }
  };

  const renderNavButton = () => {
    if (viewing === 'form') {
      return (
        <TouchableOpacity
          style={{ paddingVertical: 6 }}
          onPress={debounce(() => setViewing('intro'), 10)}
        >
          <XIcon color="#fff" size={20} />
        </TouchableOpacity>
      );
    } else {
      return <MenuButton />;
    }
  };

  return (
    <View style={{ flex: 1 }}>
      <NavBar renderLeft={renderNavButton()} title="Purchase" />

      {renderViewing()}
    </View>
  );
}

ScreenPurchase.propTypes = {
  history: PropTypes.object.isRequired,
};
