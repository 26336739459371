import PropTypes from 'prop-types';
import React from 'react';
import { Image } from 'react-native';

export function AnimalImage(props) {
  // Props
  const { char, extreme } = props;
  // Variables
  const style = {
    borderColor: '#00000011',
    borderRadius: 20,
    borderWidth: 2,
    flex: extreme ? 2 : 1,
    height: extreme ? 220 : 160,
    margin: 8,
  };

  switch (char.toString().toUpperCase()) {
    case 'B':
      return (
        <Image
          accessibilityLabel="otter"
          resizeMode="contain"
          source={require('../assets/app-animal-otter.jpg')}
          style={style}
        />
      );
    case 'C':
      return (
        <Image
          accessibilityLabel="golden retriever"
          resizeMode="contain"
          source={require('../assets/app-animal-retriever.jpg')}
          style={style}
        />
      );
    case 'D':
      return (
        <Image
          accessibilityLabel="beaver"
          resizeMode="contain"
          source={require('../assets/app-animal-beaver.jpg')}
          style={style}
        />
      );
    default:
      return (
        <Image
          accessibilityLabel="lion"
          resizeMode="contain"
          source={require('../assets/app-animal-lion.jpg')}
          style={style}
        />
      );
  }
}

AnimalImage.propTypes = {
  char: PropTypes.string.isRequired,
  extreme: PropTypes.bool.isRequired,
};
