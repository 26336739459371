// @ts-check
import PropTypes from 'prop-types';
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Spring } from 'react-spring/renderprops';

const styles = StyleSheet.create({
  statement: {
    borderRadius: 4,
    flex: 1,
    marginHorizontal: 10,
    marginVertical: 4,
    paddingVertical: 4,
  },
  statementText: {
    fontFamily: 'Roboto',
    fontSize: 18,
    textAlign: 'center',
  },
  statementView: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
});

export function Statement(props) {
  return (
    <Spring from={{ alpha: 0.8 }} to={{ alpha: props.selected ? 0.15 : 0.75 }}>
      {values => {
        const { alpha } = values;
        const backgroundColor = `rgba(255, 255, 255, ${alpha})`;
        const color = props.selected ? '#fff' : '#000';

        return (
          <View style={[styles.statement, { backgroundColor }]}>
            <View style={styles.statementView}>
              <Text style={[styles.statementText, { color }]}>
                {props.text.toString()}
              </Text>
            </View>

            <View style={{ flexDirection: 'row' }}>{props.children}</View>
          </View>
        );
      }}
    </Spring>
  );
}

Statement.propTypes = {
  children: PropTypes.array.isRequired,
  selected: PropTypes.bool,
  text: PropTypes.string.isRequired,
};
