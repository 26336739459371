//@ts-check
import React from 'react';
import { StyleSheet, Text, useWindowDimensions, View } from 'react-native';
import { ReactComponent as AnimalGridElements } from '../assets/animal-grid-elements.svg';
import { ReactComponent as AnimalGridProfiles } from '../assets/animal-grid-profiles.svg';
import descriptions from '../data/descriptions.json';
import { InfoAnimal } from './info-animal';
import { InfoButton } from './info-button';
import { InfoSection } from './info-section';
import { MenuButton } from './menu-button';
import { NavBar } from './nav-bar';

const styles = StyleSheet.create({
  boldText: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
  },
  sectionText: {
    fontFamily: 'Roboto',
    fontSize: 16,
    lineHeight: 20,
    marginVertical: 8,
  },
});

function InfoSummary(props) {
  // Variables
  const { width } = useWindowDimensions();

  let size = width * 0.8;
  if (size > 600) {
    size = 600;
  }

  return (
    <View style={{ paddingLeft: 6 }}>
      <Text style={styles.sectionText}>
        According to Empedocles, a Greek philosopher, scientist and healer who
        lived in Sicily in the fifth century B.C., all matter is comprised of
        four “roots” or elements of{' '}
        <Text style={styles.boldText}>earth, air, fire</Text> and{' '}
        <Text style={styles.boldText}>water</Text>. This Greek theory, later
        expanded by Aristotle, had a significant influence upon many personality
        inventories stretching into the 20th century. Empedocles in 444 B.C.
        recognized that people seemed to act in four distinctly different ways.
        But instead of attributing it to internal factors, he believed it was
        external environmental factors that affected the way we would act. By
        400 B.C. these four quadrants had shifted from environmental factors to
        internal factors, when another Greek, Hippocrates (father of medicine),
        redefined these quadrants as{' '}
        <Text style={styles.boldText}>Choleric, Sanguine, Phlegmatic</Text> and{' '}
        <Text style={styles.boldText}>Melancholic</Text>. He called them the 4
        Temperaments.
      </Text>

      <Text style={styles.sectionText}>
        Fast Forward to the 1900’s, where two books would redefine how we build
        a picture of a personality by combining this set of 4 basic elements;
        allowing us to start the process of modern personality profiling. These
        two books were: Williams Moulton Marston’s 1928 work, “Emotions of
        Normal People,” where he renamed the four quadrants or elements to{' '}
        <Text style={styles.boldText}>Dominance, Inducement, Compliance</Text>{' '}
        and <Text style={styles.boldText}>Submission</Text>; and Hans Jurgen
        Eysenck’s 1967 work, “Dimensions of Personality,” where he labeled the
        four as{' '}
        <Text style={styles.boldText}>
          Neurotic, Introversion, Extraversion
        </Text>{' '}
        and <Text style={styles.boldText}>Stability</Text>.
      </Text>

      <AnimalGridElements
        style={{
          alignSelf: 'center',
          height: size,
          marginBottom: 8,
          marginTop: 8,
          width: size,
        }}
      />

      <Text style={styles.sectionText}>
        From these two books was born: The PI Index, DISC Personality System,
        Merrill-Reid Personality Styles, The Hartman Color Code, The Luscher
        True Colors, David W. Keirsey Temperament Types, and The Smalley/Trent
        Animal Test to name a few. It is the Dr. Gary Smalley test using animals
        from which the Alliance takes its greatest influence to create our 4
        quadrant/2 axes inventory. We use Smalley’s four animals (Lion, Otter,
        Beaver and Golden Retriever) to define our four personality types. But
        this is where the comparison begins and ends. The Alliance Animal Test
        takes the four animals and breaks them down into 16 categories of animal
        combinations which are represented by 16 words.
      </Text>

      <AnimalGridProfiles
        style={{
          alignSelf: 'center',
          height: size,
          marginBottom: 8,
          marginTop: 8,
          width: size,
        }}
      />

      <Text style={styles.sectionText}>
        Our 42 question test (formatted into 21 statements and 21 vocabulary)
        requires the user to rank the statements and words from{' '}
        <Text style={styles.boldText}>4</Text> (most descriptive),{' '}
        <Text style={styles.boldText}>3</Text> (2nd most descriptive),{' '}
        <Text style={styles.boldText}>2</Text> (3rd most descriptive) and{' '}
        <Text style={styles.boldText}>1</Text>
        (least descriptive). Afterwards, your results not only reflect which
        animal is your primary style{' '}
        <Text style={styles.boldText}>(extreme)</Text> and which is your
        secondary style <Text style={styles.boldText}>(moderate)</Text>, but
        places you into one of the 16 categories, where you discover a drilled
        down description of your personality (strengths and weaknesses).
      </Text>
    </View>
  );
}

export function ScreenAnimals(props) {
  // State
  const [selected, setSelected] = React.useState('about');

  const handlePress = select => () => {
    setSelected(select);
  };

  const renderInfo = option => {
    const type = option.type.toLowerCase();
    switch (type) {
      case 'summary':
        return <InfoSummary {...option} />;
      case 'animal':
        return <InfoAnimal {...option} />;
      default:
        return <View />;
    }
  };

  const options = [];
  // First option is summary
  options.push({ title: 'about', type: 'summary' });
  // The Four animals
  Object.keys(descriptions).forEach(description => {
    options.push({
      data: descriptions[description],
      title: description.toLowerCase(),
      type: 'animal',
    });
  });

  return (
    <View style={{ flex: 1 }}>
      <NavBar renderLeft={<MenuButton />} title="Animals" />

      <View style={{ flex: 1, paddingHorizontal: 8 }}>
        {options.map(option => {
          const { title } = option;
          if (title === selected) {
            return (
              <InfoSection
                key={title}
                option={option}
                renderInfo={renderInfo}
              />
            );
          } else {
            return (
              <InfoButton
                handlePress={handlePress}
                key={title}
                option={option}
              />
            );
          }
        })}
      </View>
    </View>
  );
}

ScreenAnimals.propTypes = {};
