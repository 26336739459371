// @ts-check
import PropTypes from 'prop-types';
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import theme from '../theme';

const styles = StyleSheet.create({
  navBar: {
    alignItems: 'center',
    flexDirection: 'row',
    padding: 8,
  },
  title: {
    color: '#fff',
    fontFamily: 'Roboto',
    fontSize: 24,
    fontWeight: 'bold',
    textShadowColor: theme.colors.textShadowColor,
    textShadowOffset: {
      height: 2,
      width: 0,
    },
    textShadowRadius: 2,
    textTransform: 'uppercase',
  },
});

export function NavBar(props) {
  return (
    <View style={styles.navBar}>
      <View style={{ flex: 1, flexDirection: 'row' }}>
        {Boolean(props.renderLeft) && props.renderLeft}

        <View style={{ flex: 1 }} />
      </View>

      <Text style={styles.title}>{props.title}</Text>

      <View style={{ alignItems: 'flex-end', flex: 1 }}>
        {Boolean(props.renderRight) && props.renderRight}
      </View>
    </View>
  );
}

NavBar.propTypes = {
  renderLeft: PropTypes.element,
  renderRight: PropTypes.element,
  title: PropTypes.string.isRequired,
};
