// @ts-check
import { darken } from 'polished';

export default {
  colors: {
    primary: '#163BFF',
    secondary: '#16B9FF',
    textShadowColor: darken(0.2, '#163BFF'),
  },
};
