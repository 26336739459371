import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { animated, useSpring } from 'react-spring/native';

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgba(255, 255, 255, 0.90)',
    padding: 8,
  },
  orderText: {
    fontFamily: 'Roboto',
    fontSize: 18,
    fontWeight: 'bold',
    margin: 8,
  },
  orderView: {
    alignItems: 'center',
    borderColor: '#aaa',
    borderStyle: 'dashed',
    borderWidth: 2,
    flexDirection: 'row',
  },
});

const Animated = {
  View: animated(View),
};

export function PurchaseFormItemPrice(props) {
  // Variables
  const values = useSpring({
    from: { marginBottom: 8, marginTop: 40 },
    to: { marginBottom: 0, marginTop: 0 },
  });
  const { marginBottom, marginTop } = values;

  return (
    <Animated.View style={{ marginBottom, marginTop }}>
      <View style={styles.container}>
        <View style={styles.orderView}>
          <Text style={[styles.orderText, { fontSize: 18 }]}>
            The Animal Inventory
          </Text>

          <View style={{ flex: 1 }} />

          <Text style={[styles.orderText, { fontSize: 22 }]}>
            ${process.env.REACT_APP_PRICE_WEB}
          </Text>
        </View>
      </View>
    </Animated.View>
  );
}
