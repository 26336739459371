// @ts-check
import descriptions from '../../data/descriptions.json';

/**
 * Get an array of Animals from the users assessment results
 *
 * @typedef {Object} Animal
 * @property {string} animal
 * @property {string} char
 * @property {Object} description
 * @property {number} num
 * @property {string} significance
 * @property {string} type
 *
 * @typedef {Object} Score
 * @property {string} char - a or b or c or d
 * @property {number} num - The total of selections for the letter
 *
 * @param {Object} params
 * @param {number} params.questionsCount - Amount of questions in assessment
 * @param {Score[]} params.results - [ { char: 'a', num: 110 }, ... ]
 *
 * @returns {{ animals: Animal[] }} { animals: [ { animal: 'golden retriever', char: 'c', ... }, ... ] }
 *
 * @example
 * const { animals } = getAnimals({
 *   questionsCount: 42,
 *   results: [{ char: "b", num: 133 }, { char: "d", num: 113 }]
 * });
 */
export function getAnimals({ questionsCount, results }) {
  // The array of animals to be returned
  const animals = [];

  // resultsAnimals: [ { char: 'c', num: 118 }, { char: 'b', num: 113 } ]
  const resultsAnimals = [];
  if (results.length === 1) {
    // This user only had one significant animal
    resultsAnimals.push(results[0]);
  } else if (results.length === 2) {
    // This user had two significant animals
    // Which should be the result of most assessments
    resultsAnimals.push(results[0]);
    resultsAnimals.push(results[1]);
  } else if (results.length === 3) {
    if (results[1].num === results[2].num) {
      // If the second two results are the same
      // Then only the first result really matters
      resultsAnimals.push(results[0]);
    } else if (results[0].num !== results[2].num) {
      // Make sure all three results don't have the same value
      resultsAnimals.push(results[0]);
      resultsAnimals.push(results[1]);
    }
  }

  // There are 4 possible animals
  const animalOptions = {
    a: 'lion',
    b: 'otter',
    c: 'golden retriever',
    d: 'beaver',
  };

  // Get the name and description for each animal
  resultsAnimals.forEach(value => {
    const { char, num } = value;
    const animal = animalOptions[char];
    const description = descriptions[animal];
    const type = 'animal';
    const threshold = questionsCount * 3;
    const significance = num >= threshold ? 'extreme' : 'moderate';
    animals.push({ animal, char, description, num, significance, type });
  });

  return { animals };
}
