// @ts-check
import { getAnimals } from './get-animals';
import { getTraits } from './get-traits';

/**
 * Get the users Animals and Traits from their assessment results
 *
 * @typedef {Object} Animal
 * @property {string} animal
 * @property {string} char
 * @property {Object} description
 * @property {number} num
 * @property {string} significance
 * @property {string} type
 *
 * @typedef {Object} Trait
 * @property {Object} profile
 * @property {string} profile.intro
 * @property {string} profile.strength
 * @property {string} profile.weakness
 * @property {string} trait
 * @property {string} type
 *
 * @param {{ results: (Object|string) }} params { results: { a: 96, b: 113, c: 118, d: 93 } }
 *
 * @returns {{ animals: Animal[], traits: Trait[] }} { animals: [ { animal: 'lion', ... }, ... ], traits: [ { profile: [Object], ... }, ... ] }
 *
 * @example
 * const { animals, traits } = scoreAssessment({
 *   results: { a: 76, b: 133, c: 98, d: 113 }
 * });
 *
 * const { animals, traits } = scoreAssessment({
 *   results: "{\"a\": 96,\"b\": 113,\"c\": 93,\"d\": 118}"
 * });
 */
export function scoreAssessment({ results }) {
  // resultsParsed: { a: 96, b: 113, c: 118, d: 93 }
  let resultsParsed = results;
  if (typeof results === 'string') {
    resultsParsed = JSON.parse(results);
  }

  // values: [ 96, 113, 118, 93 ]
  const values = Object.values(resultsParsed);

  // The sum of values will always be 10 times the total number of questions
  // ABCD each have a total [ 96, 113, 118, 93 ] = 420
  // resultsSum: 420
  const resultsSum = values.reduce((a, b) => a + b, 0);

  // Each questions is ranked [ 1, 2, 3, 4 ] = 10
  const rankTotal = 10;

  // 420 / 10 equals 42 questions
  // questionsCount: 42
  const questionsCount = resultsSum / rankTotal;

  // Convert the results object into an array for sorting and filtering
  // resultsArray: [ { char: 'a', num: 96 }, { char: 'b', num: 113 }, {...}, {...} ]
  const resultsArray = [];
  Object.keys(resultsParsed).forEach(value => {
    resultsArray.push({ char: value, num: resultsParsed[value] });
  });

  // Sort the results array by num descending
  // resultsArray: [ { char: 'c', num: 118 }, { char: 'b', num: 113 }, {...}, {...} ]
  resultsArray.sort((a, b) => b.num - a.num);

  // Values below the minimum threshold don't matter
  // minThreshold: 105
  const minThreshold = questionsCount * 2.5;

  // Filter the array to only get the values that are greater than or equal to the threshold
  // resultsFiltered: [ { char: 'c', num: 118 }, { char: 'b', num: 113 } ]
  const resultsFiltered = resultsArray.filter(val => val.num >= minThreshold);

  // An array of animals based on the assessment results
  // animals: [
  //   { animal: 'golden retriever', char: 'c', description: [Object], num: 118, significance: 'moderate', type: 'animal' },
  //   { animal: 'otter', char: 'b', description: [Object], num: 113, significance: 'moderate', type: 'animal' }
  // ]
  const { animals } = getAnimals({ questionsCount, results: resultsFiltered });

  // An array of traits based on the assessment results
  // traits: [ { profile: [Object], trait: 'ACCOMMODATOR', type: 'trait' } ]
  const { traits } = getTraits({ questionsCount, results: resultsFiltered });

  // Throw an error if the Assessment returns no Animals or Traits
  if (!animals.length && !traits.length) {
    throw new Error('Assessment results returned no Animals or Traits');
  }

  return { animals, traits };
}
