// @ts-check
import shuffle from 'lodash/shuffle';
import PropTypes from 'prop-types';
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { ProgressButton } from './progress-button';
import { RankGuide } from './rank-guide';
import { Statement } from './statement';

const styles = StyleSheet.create({
  tutorialText: {
    color: '#fff',
    fontFamily: 'Roboto',
    fontSize: 24,
    textAlign: 'center',
  },
  tutorialView: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 8,
    paddingHorizontal: 20,
  },
});

export function TutorialStepThree(props) {
  const selections = { a: 4, b: 3, c: 2, d: 1 };

  let instructions =
    'All four statements must be ranked from 1 to 4. You cannot use the same number more than once.';
  if (props.assessmentCode) {
    instructions = instructions + ' When you are ready to begin press start.';
  }

  return (
    <View style={{ flex: 1 }}>
      <View style={styles.tutorialView}>
        <Text style={[styles.tutorialText, { fontSize: 16 }]}>
          {instructions}
        </Text>
      </View>

      <View style={{ flex: 1 }}>
        <View style={{ paddingHorizontal: 10 }}>
          <RankGuide />
        </View>

        {shuffle(Object.keys(props.question)).map(key => {
          const statementSelected = selections.hasOwnProperty(key);

          return (
            <Statement
              key={key}
              selected={statementSelected}
              text={props.question[key]}
            >
              {[1, 2, 3, 4].map(value => {
                let disabled = false;
                let selected = false;
                if (selections[key] === value) {
                  selected = true;
                } else if (selections.hasOwnProperty(key)) {
                  disabled = true;
                }

                Object.keys(selections).forEach(k => {
                  const isNotCurrent = k !== key;
                  const optionSelected = selections[k] === value;
                  if (isNotCurrent && optionSelected) {
                    disabled = true;
                  }
                });

                return (
                  <ProgressButton
                    disabled={disabled}
                    key={value}
                    selected={selected}
                    text={value.toString()}
                    onPress={() => ({})}
                  />
                );
              })}
            </Statement>
          );
        })}
      </View>
    </View>
  );
}

TutorialStepThree.propTypes = {
  assessmentCode: PropTypes.string,
  question: PropTypes.object.isRequired,
};
