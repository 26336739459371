import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import ChevronRight from 'react-feather/dist/icons/chevron-right';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { animated, useSpring } from 'react-spring/native';

const styles = StyleSheet.create({
  selectedTitle: {
    color: '#000',
    fontFamily: 'Roboto',
    fontSize: 18,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  selectedTitleView: {
    alignItems: 'center',
    borderBottomColor: '#fafafa',
    borderBottomStyle: 'solid',
    borderBottomWidth: StyleSheet.hairlineWidth,
    flexDirection: 'row',
    padding: 8,
  },
  selectedView: {
    backgroundColor: '#fff',
    borderRadius: 4,
    flex: 1,
    marginBottom: 8,
  },
  significance: {
    color: '#3700d5',
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: 'bold',
    marginLeft: 8,
    textTransform: 'uppercase',
  },
});

const Animated = {
  Text: animated(Text),
  View: animated(View),
};

export function InfoSection(props) {
  // Props
  const { title } = props.option;
  // Variables
  const significance = get(props.option, 'significance', '');

  const values = useSpring({
    from: { paddingLeft: 0, rotate: 0 },
    to: { paddingLeft: 8, rotate: 90 },
  });
  const { paddingLeft, rotate } = values;
  const transform = rotate.interpolate(r => [{ rotate: `${r}deg` }]);

  return (
    <View style={styles.selectedView}>
      <View style={styles.selectedTitleView}>
        <Animated.Text style={[styles.selectedTitle, { paddingLeft }]}>
          {title}
        </Animated.Text>

        {Boolean(significance) && (
          <Text style={styles.significance}>{significance}</Text>
        )}

        <View style={{ flex: 1 }} />

        <Animated.View style={{ transform }}>
          <ChevronRight color="#999" size={18} />
        </Animated.View>
      </View>

      <ScrollView
        contentContainerStyle={{ paddingLeft: 2, paddingRight: 8 }}
        style={{ flex: 1, paddingTop: 4 }}
      >
        {props.renderInfo(props.option)}
      </ScrollView>
    </View>
  );
}

InfoSection.propTypes = {
  option: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }),
  renderInfo: PropTypes.func.isRequired,
};
