// @ts-check
import PropTypes from 'prop-types';
import qs from 'qs';
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Link } from 'react-router-dom';
import { MenuButton } from './menu-button';
import { NavBar } from './nav-bar';
import { ProgressButton } from './progress-button';
import { ProgressIndicator } from './progress-indicator';
import { TutorialStepOne } from './tutorial-step-one';
import { TutorialStepThree } from './tutorial-step-three';
import { TutorialStepTwo } from './tutorial-step-two';

const styles = StyleSheet.create({
  buttonSkipText: {
    color: 'rgba(255, 255, 255, 0.4)',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  progressSection: {
    alignItems: 'center',
    flexDirection: 'row',
    padding: 10,
  },
});

export function ScreenTutorial(props) {
  let assessmentCode = '';
  if (window.location.href.includes('?')) {
    const query = window.location.href.split('?').pop();
    const { code } = qs.parse(query);
    assessmentCode = code;
  }

  // State for step 1
  const [hasSelected, setHasSelected] = React.useState(false);
  const [selected, setSelected] = React.useState(0);

  // State for step 2
  const [hasUnselected, setHasUnselected] = React.useState(false);
  const [unselected, setUnselected] = React.useState(3);

  const [step, setStep] = React.useState(1);

  const statementSelect = select => () => {
    setHasSelected(true);
    setSelected(select);
  };

  const statementUnselect = () => {
    setHasUnselected(true);
    setUnselected(null);
  };

  const nextStep = () => {
    if (step === 3) {
      if (assessmentCode) {
        props.history.push(`/assessment/${assessmentCode}`);
      } else {
        props.history.push('/menu');
      }
    } else {
      setStep(step + 1);
    }
  };

  const renderStep = () => {
    const question = {
      a: 'Takes Charge.',
      b: 'Takes Risks.',
      c: 'Loyal.',
      d: 'Accurate.',
    };

    switch (step) {
      case 1:
        return (
          <TutorialStepOne
            selected={selected}
            statementSelect={statementSelect}
          />
        );
      case 2:
        return (
          <TutorialStepTwo
            statementUnselect={statementUnselect}
            unselected={unselected}
          />
        );
      case 3:
        return (
          <TutorialStepThree
            assessmentCode={assessmentCode}
            question={question}
          />
        );
      default:
        return (
          <TutorialStepOne
            selected={selected}
            statementSelect={statementSelect}
          />
        );
    }
  };

  const progress = step / 3;

  let buttonText = 'Next';
  if (step === 3 && assessmentCode) {
    buttonText = 'Start';
  } else if (step === 3) {
    buttonText = 'Menu';
  }

  let buttonDisabled = true;
  if (step === 1 && hasSelected) {
    buttonDisabled = false;
  } else if (step === 2 && hasUnselected) {
    buttonDisabled = false;
  } else if (step === 3) {
    buttonDisabled = false;
  }

  return (
    <View style={{ flex: 1 }}>
      <NavBar
        renderLeft={<MenuButton />}
        renderRight={
          <Link
            style={{ padding: 6, textDecorationLine: 'none' }}
            to={`/assessment/${assessmentCode}`}
          >
            <Text style={styles.buttonSkipText}>Skip</Text>
          </Link>
        }
        title="Tutorial"
      />

      <View style={{ flex: 1 }}>{renderStep()}</View>

      <View style={styles.progressSection}>
        <View style={{ flex: 1, marginRight: 20 }}>
          <ProgressIndicator progress={progress} />
        </View>

        <ProgressButton
          buttonStyles={{ flex: null }}
          disabled={buttonDisabled}
          text={buttonText}
          onPress={nextStep}
        />
      </View>
    </View>
  );
}

ScreenTutorial.propTypes = {
  history: PropTypes.object.isRequired,
};
