import PropTypes from 'prop-types';
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { animated, config, useSpring } from 'react-spring/native';
import theme from '../theme';
import { ProgressButton } from './progress-button';

const styles = StyleSheet.create({
  circle: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: 200,
    position: 'absolute',
    zIndex: -1,
  },
  codeText: {
    color: '#222',
    fontFamily: 'Roboto Mono, monospace',
    fontSize: 24,
    fontWeight: 'bold',
  },
  codeView: {
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    borderRadius: 50,
    margin: 10,
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  exampleText: {
    color: '#fff',
    fontFamily: 'Roboto',
    fontSize: 10,
    fontWeight: 'bold',
    letterSpacing: 1.1,
    textTransform: 'uppercase',
  },
  exampleView: {
    backgroundColor: '#16B9FF',
    borderRadius: 50,
    paddingHorizontal: 8,
    paddingVertical: 2,
    position: 'absolute',
    zIndex: 1,
  },
  introText: {
    color: '#fff',
    fontFamily: 'Roboto',
    fontSize: 18,
    maxWidth: 400,
    paddingHorizontal: 8,
    textAlign: 'center',
  },
  screen: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  section: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  textDiscover: {
    color: '#fff',
    fontFamily: 'Roboto',
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  title: {
    color: '#fff',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    textShadowColor: theme.colors.textShadowColor,
    textShadowOffset: {
      height: 3,
      width: 0,
    },
    textShadowRadius: 2,
  },
  titleSection: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    paddingVertical: 40,
  },
  titleSubText: {
    color: '#fff',
    fontFamily: 'Roboto',
    fontSize: 18,
    fontWeight: 'bold',
    textShadowColor: theme.colors.textShadowColor,
    textShadowOffset: {
      height: 1,
      width: 0,
    },
    textShadowRadius: 2,
    textTransform: 'uppercase',
  },
});

const Animated = {
  Text: animated(Text),
  View: animated(View),
};

export function PurchaseIntro(props) {
  const values = useSpring({
    config: config.slow,
    from: { margin: 8, opacity: 0, size: 100 },
    to: { margin: 0, opacity: 1, size: 150 },
  });
  const { margin, opacity, size } = values;
  const textStyles = [styles.title, { margin, opacity }];
  const circleStyles = [styles.circle, { height: size, opacity, width: size }];

  let priceView = <View />;
  if (process.env.REACT_APP_PRICE_WEB) {
    const price = process.env.REACT_APP_PRICE_WEB.split('.');
    const dollars = price[0];
    const cents = price[1];
    priceView = (
      <Animated.Text style={textStyles}>
        <Text style={{ fontSize: 24 }}>$</Text>
        <Text style={{ fontSize: 48 }}>{dollars}</Text>
        <Text style={{ fontSize: 36 }}>.{cents}</Text>
      </Animated.Text>
    );
  }

  return (
    <View style={styles.screen}>
      <View style={{ flex: 2 }}>
        <View style={styles.titleSection}>
          {priceView}

          <Animated.View style={{ alignItems: 'center', opacity }}>
            <Text style={styles.titleSubText}>Assessment</Text>
            <Text style={styles.titleSubText}>Code</Text>
          </Animated.View>

          <Animated.View style={circleStyles} />
        </View>
      </View>

      <View style={styles.section}>
        <Text style={styles.introText}>
          A single-use code is required to take the assessment. You can purchase
          one for yourself or give it to someone else.
        </Text>
      </View>

      <View style={styles.section}>
        <View>
          <View style={styles.exampleView}>
            <Text style={styles.exampleText}>Example Code</Text>
          </View>

          <View style={styles.codeView}>
            <Text style={styles.codeText}>2W0EKaVNO</Text>
          </View>
        </View>
      </View>

      <View style={styles.section}>
        <Text style={styles.textDiscover}>Discover your animal!</Text>
      </View>

      <View style={styles.section}>
        <ProgressButton
          buttonStyles={{ flex: null }}
          text="Purchase Code"
          onPress={() => props.setViewing('form')}
        />
      </View>
    </View>
  );
}

PurchaseIntro.propTypes = {
  setViewing: PropTypes.func.isRequired,
};
