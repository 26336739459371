// @ts-check
import PropTypes from 'prop-types';
import React from 'react';
import { View } from 'react-native';
import { AppContext, TYPES } from '../contexts/app-context';
import { validCode } from '../utils/valid-code';
import { MenuButton } from './menu-button';
import { NavBar } from './nav-bar';
import { Results } from './results';
import { ResultsFetch } from './results-fetch';

export function ScreenResults(props) {
  // Context
  const context = React.useContext(AppContext);
  const { results } = context.state;
  // Props
  const { history, match } = props;
  const { code } = match.params;
  // Variables
  const { isValidCode } = validCode({ code });

  if (!isValidCode) {
    if (!context.state.err.render) {
      context.dispatch({
        payload: {
          buttons: [
            {
              onPress: () => {
                history.push('/menu');
                context.dispatch({ type: TYPES.ERR_RESET });
              },
              text: 'Try again',
            },
          ],
          code,
          render: true,
          type: 'validation',
        },
        type: TYPES.ERR_CREATE,
      });
    }
    return <View />;
  } else if (results.a !== 0) {
    return (
      <View style={{ flex: 1 }}>
        <NavBar renderLeft={<MenuButton />} title="Results" />

        <Results />
      </View>
    );
  } else {
    return <ResultsFetch code={code} history={history} />;
  }
}

ScreenResults.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};
