// @ts-check
import shuffle from 'lodash/shuffle';
import PropTypes from 'prop-types';
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { AppContext, TYPES } from '../contexts/app-context';
import { calculateTotals } from '../utils/calculate-totals';
import { ProgressButton } from './progress-button';
import { ProgressIndicator } from './progress-indicator';
import { RankGuide } from './rank-guide';
import { Statement } from './statement';

const styles = StyleSheet.create({
  instructionText: {
    color: '#fff',
    fontFamily: 'Roboto',
    fontSize: 14,
    textAlign: 'center',
  },
  instructionView: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
  },
  progressSection: {
    alignItems: 'center',
    flexDirection: 'row',
    padding: 10,
  },
});

export function Assessment(props) {
  // Context
  const { dispatch, state } = React.useContext(AppContext);
  const { questions } = state;
  // State
  const [keys, setKeys] = React.useState(['a', 'b', 'c', 'd']);
  const [questionIndex, setQuestionIndex] = React.useState(0);
  const [selections, setSelections] = React.useState({});
  const [totals, setTotals] = React.useState({ a: 0, b: 0, c: 0, d: 0 });
  // Variables
  const question = questions[questionIndex];
  const questionKeys = keys;
  const progress = (questionIndex + 1) / questions.length;
  const nextDisabled = Boolean(Object.keys(selections).length !== 4);

  const logTotals = () => {
    // @ts-ignore
    const { a, b, c, d } = selections;
    const { newTotals } = calculateTotals({ selections, totals });
    console.log(questionIndex, { a, b, c, d }, newTotals);
  };

  const handlePress = (key, number) => () => {
    if (selections.hasOwnProperty(key)) {
      // They are deselecting
      const allSelections = selections;
      delete allSelections[key];
      setSelections({ ...allSelections });
    } else {
      // They are selecting
      setSelections({ ...selections, [key]: number });
    }
  };

  const handleNext = () => {
    logTotals();
    // Calculate the new totals
    const { newTotals } = calculateTotals({ selections, totals });
    setTotals(newTotals);
    // Show the next question
    setQuestionIndex(questionIndex + 1);
    // Shuffle the order of the keys
    setKeys(shuffle(['a', 'b', 'c', 'd']));
    // Clear the selections
    setSelections({});
  };

  const handleFinish = () => {
    logTotals();
    // Calculate the final totals
    const { newTotals } = calculateTotals({ selections, totals });
    // Store the final totals in global state
    dispatch({ payload: { results: newTotals }, type: TYPES.RESULTS_SET });
    // Show the AssessmentSubmit component
    props.setReadyToSubmit(true);
  };

  const renderProgressButton = () => {
    if (questionIndex + 1 === questions.length) {
      return (
        <ProgressButton
          buttonStyles={{ flex: null }}
          disabled={nextDisabled}
          text="Finish"
          onPress={handleFinish}
        />
      );
    } else {
      return (
        <ProgressButton
          buttonStyles={{ flex: null }}
          disabled={nextDisabled}
          text="Next"
          onPress={handleNext}
        />
      );
    }
  };

  return (
    <View style={{ flex: 1 }}>
      <View style={styles.instructionView}>
        <Text style={styles.instructionText}>
          Assign the following statements a value according to how well you
          believe the statement reflects upon you.
        </Text>
      </View>

      <View style={{ paddingHorizontal: 10 }}>
        <RankGuide />
      </View>

      <View style={{ flex: 1 }}>
        {questionKeys.map(key => {
          const statementSelected = selections.hasOwnProperty(key);

          return (
            <Statement
              key={key}
              selected={statementSelected}
              text={question[key]}
            >
              {[1, 2, 3, 4].map(value => {
                let disabled = false;
                let selected = false;
                if (selections[key] === value) {
                  selected = true;
                } else if (selections.hasOwnProperty(key)) {
                  disabled = true;
                }

                questionKeys.forEach(k => {
                  const isNotCurrent = k !== key;
                  const optionSelected = selections[k] === value;
                  if (isNotCurrent && optionSelected) {
                    disabled = true;
                  }
                });

                return (
                  <ProgressButton
                    disabled={disabled}
                    key={value}
                    selected={selected}
                    text={value.toString()}
                    onPress={handlePress(key, value)}
                  />
                );
              })}
            </Statement>
          );
        })}
      </View>

      <View style={styles.progressSection}>
        <View style={{ flex: 1, marginRight: 20 }}>
          <ProgressIndicator progress={progress} />
        </View>

        {renderProgressButton()}
      </View>
    </View>
  );
}

Assessment.propTypes = {
  setReadyToSubmit: PropTypes.func.isRequired,
};
