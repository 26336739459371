// @ts-check
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { AppContext } from '../contexts/app-context';
import { scoreAssessment } from '../utils/score-assessment';
import { AnimalImage } from './animal-image';
import { InfoAnimal } from './info-animal';
import { InfoButton } from './info-button';
import { InfoSection } from './info-section';
import { InfoTrait } from './info-trait';

const styles = StyleSheet.create({
  section: {
    flexDirection: 'row',
    marginVertical: 8,
  },
  sectionBorder: {
    backgroundColor: '#7A8FFF',
    borderRadius: 2,
    width: 4,
  },
  sectionData: {
    fontFamily: 'Roboto',
    fontSize: 16,
    lineHeight: 20,
  },
  sectionTitle: {
    fontFamily: 'Roboto',
    fontSize: 20,
    fontWeight: 'bold',
  },
  sectionView: {
    flex: 1,
    paddingLeft: 8,
  },
});

function InfoSummary(props) {
  return (
    <View>
      <View style={styles.section}>
        <View style={styles.sectionBorder} />

        <View style={styles.sectionView}>
          <Text style={styles.sectionData}>
            Animals and traits have been assigned to you based on how you ranked
            each statement in the assessment.
          </Text>
        </View>
      </View>

      <View style={{ alignItems: 'center', flexDirection: 'row' }}>
        {props.imageOptions.map(option => {
          return (
            <AnimalImage
              char={option.char}
              extreme={option.extreme}
              key={option.char}
            />
          );
        })}
      </View>

      <View style={styles.section}>
        <View style={styles.sectionBorder} />

        <View style={styles.sectionView}>
          <Text style={styles.sectionData}>
            Click on each option below to learn more about how each one relates
            to your personality.
          </Text>
        </View>
      </View>
    </View>
  );
}

export function Results(props) {
  // Context
  const context = React.useContext(AppContext);
  const { results } = context.state;
  // State
  const [selected, setSelected] = React.useState('your results');

  const handlePress = select => () => {
    setSelected(select);
  };

  // Get the users Animals and Traits from their assessment results
  const { animals, traits } = scoreAssessment({ results });

  const imageOptions = [];
  const options = [];
  // First option is summary
  options.push({ title: 'your results', type: 'summary' });
  // The Animals
  animals.forEach(animal => {
    // Images for the animals displayed in summary
    imageOptions.push({
      char: animal.char,
      extreme: animal.significance === 'extreme',
    });
    options.push({
      data: animal.description,
      significance: animal.significance,
      title: animal.animal.toLowerCase(),
      type: animal.type,
    });
  });
  // The Traits
  traits.forEach(trait => {
    options.push({
      data: trait.profile,
      title: trait.trait.toLowerCase(),
      type: trait.type,
    });
  });

  const renderInfo = option => {
    const type = option.type.toLowerCase();
    switch (type) {
      case 'summary':
        return <InfoSummary {...option} imageOptions={imageOptions} />;
      case 'animal':
        return <InfoAnimal {...option} />;
      case 'trait':
        return <InfoTrait {...option} />;
      default:
        return <View />;
    }
  };

  return (
    <View style={{ flex: 1, paddingHorizontal: 8 }}>
      {options.map(option => {
        const { title } = option;
        if (title === selected) {
          return (
            <InfoSection key={title} option={option} renderInfo={renderInfo} />
          );
        } else {
          return (
            <InfoButton handlePress={handlePress} key={title} option={option} />
          );
        }
      })}
    </View>
  );
}

Results.propTypes = {};
