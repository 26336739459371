// @ts-check
import { serializeError } from 'serialize-error';
import { checkParams } from '../utils/check-params';

/**
 * @param {Object} params
 * @param {Object} params.data
 */
export function captureError(params) {
  checkParams({ functionName: 'captureError', functionParams: params });

  if (process.env.NODE_ENV === 'production') {
    if (params.data) {
      const body = {};
      body.data = params.data;
      if (params.data.error) {
        body.data.error = serializeError(params.data.error);
      }
      if (window.navigator && window.navigator.userAgent) {
        body.data.userAgent = window.navigator.userAgent;
      }
      if (process.env.REACT_APP_AWS_BRANCH) {
        body.data.AWS_BRANCH = process.env.REACT_APP_AWS_BRANCH;
      }

      fetch(process.env.REACT_APP_ALERTS_URL, {
        body: JSON.stringify(body),
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
      });
    }
  }

  console.log({ captureError: params });
}
