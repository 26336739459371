import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React from 'react';
import { StyleSheet, Text, TouchableOpacity } from 'react-native';
import { animated, useSpring } from 'react-spring/native';

const buttonStyles = {
  alignItems: 'center',
  borderRadius: 4,
  justifyContent: 'center',
  marginHorizontal: 4,
  paddingHorizontal: 12,
  paddingVertical: 4,
};

const textStyles = {
  fontFamily: 'Roboto',
  fontSize: 18,
  fontWeight: 'bold',
  textTransform: 'uppercase',
};

const styles = StyleSheet.create({
  buttonDefault: {
    ...buttonStyles,
    backgroundColor: '#fff',
    borderBottomColor: '#95A6FF',
    borderBottomWidth: 2,
    marginTop: 2,
  },
  buttonDisabled: {
    ...buttonStyles,
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    borderBottomColor: 'transparent',
    borderBottomWidth: 2,
    marginTop: 2,
  },
  buttonSelected: {
    ...buttonStyles,
    borderColor: '#fff',
    borderWidth: 2,
  },
  textDefault: {
    ...textStyles,
    color: '#222',
  },
  textDisabled: {
    ...textStyles,
    color: '#444',
  },
  textSelected: {
    ...textStyles,
    color: '#fff',
  },
});

const Animated = {
  TouchableOpacity: animated(TouchableOpacity),
};

export function ProgressButton(props) {
  let styledButton = styles.buttonDefault;
  let styledText = styles.textDefault;
  if (props.disabled) {
    styledButton = styles.buttonDisabled;
    styledText = styles.textDisabled;
  } else if (props.selected) {
    styledButton = styles.buttonSelected;
    styledText = styles.textSelected;
  }

  const values = useSpring({ flex: props.selected ? 1.2 : 1 });
  const btnStyles = [styledButton, { flex: values.flex }, props.buttonStyles];

  return (
    <Animated.TouchableOpacity
      disabled={props.disabled}
      style={btnStyles}
      onPress={debounce(props.onPress, 10)}
    >
      <Text style={[styledText, props.textStyles]}>
        {props.text.toString()}
      </Text>
    </Animated.TouchableOpacity>
  );
}

ProgressButton.propTypes = {
  buttonStyles: PropTypes.object,
  disabled: PropTypes.bool,
  onPress: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  text: PropTypes.string.isRequired,
  textStyles: PropTypes.object,
};
