// @ts-check
import PropTypes from 'prop-types';
import React from 'react';
import CheckCircle from 'react-feather/dist/icons/check-circle';
import { StyleSheet, Text, View } from 'react-native';
import { Link } from 'react-router-dom';
import { config, Spring } from 'react-spring/renderprops';
import { ProgressButton } from './progress-button';

const styles = StyleSheet.create({
  codeText: {
    color: '#222',
    fontFamily: 'Roboto Mono, monospace',
    fontSize: 24,
    fontWeight: 'bold',
  },
  codeView: {
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    borderRadius: 50,
    margin: 10,
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  exampleText: {
    color: '#fff',
    fontFamily: 'Roboto',
    fontSize: 10,
    fontWeight: 'bold',
    letterSpacing: 1.1,
    textTransform: 'uppercase',
  },
  exampleView: {
    backgroundColor: '#16B9FF',
    borderRadius: 50,
    paddingHorizontal: 8,
    paddingVertical: 2,
    position: 'absolute',
    zIndex: 1,
  },
  instructionText: {
    color: '#fff',
    fontFamily: 'Roboto',
    fontSize: 18,
    maxWidth: 400,
    padding: 8,
    textAlign: 'center',
  },
  screen: {
    alignItems: 'center',
    flex: 1,
  },
  section: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
});

export function PurchaseComplete(props) {
  // Props
  const { code } = props;

  return (
    <View style={styles.screen}>
      <View style={[styles.section, { flex: 2 }]}>
        <Spring
          config={config.slow}
          from={{ opacity: 0, rotate: 180, size: 40 }}
          to={{ opacity: 1, rotate: 0, size: 90 }}
        >
          {values => {
            const { opacity, rotate, size } = values;
            const transform = [{ rotate: `${rotate}deg` }];
            return (
              <View style={{ opacity, transform }}>
                <CheckCircle color="#fff" size={size} />
              </View>
            );
          }}
        </Spring>
      </View>

      <View style={styles.section}>
        <View>
          <View style={styles.exampleView}>
            <Text style={styles.exampleText}>Your Code</Text>
          </View>

          <View style={styles.codeView}>
            <Text style={styles.codeText}>{code}</Text>
          </View>
        </View>
      </View>

      <View style={styles.section}>
        <Text style={styles.instructionText}>
          You should receive an email with a copy of your code; please check
          your spam mail. The code can be used to take the assessment when it’s
          convenient for you. If you want to take the assessment now press the
          button below.
        </Text>
      </View>

      <View style={styles.section}>
        <Link
          style={{ textDecorationLine: 'none' }}
          to={`/tutorial?code=${code}`}
        >
          <ProgressButton
            buttonStyles={{ flex: null }}
            text="Begin Assessment"
            onPress={() => ({})}
          />
        </Link>
      </View>
    </View>
  );
}

PurchaseComplete.propTypes = {
  code: PropTypes.string.isRequired,
};
