// @ts-check
import React from 'react';
import { Helmet } from 'react-helmet';

export function AuthorizeAccept() {
  return (
    <Helmet>
      <script src={process.env.REACT_APP_ACCEPT_UI} type="text/javascript" />
    </Helmet>
  );
}

AuthorizeAccept.propTypes = {};
