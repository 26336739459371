import shuffle from 'lodash/shuffle';
import React from 'react';

export const TYPES = {
  ERR_CREATE: 'ERR_CREATE',
  ERR_RESET: 'ERR_RESET',
  QUESTIONS_SET: 'QUESTIONS_SET',
  RESULTS_SET: 'RESULTS_SET',
};

const initialState = {
  err: { buttons: [], code: '', render: false, type: '' },
  questions: [],
  results: { a: 0, b: 0, c: 0, d: 0 },
};

const reducer = (state, action) => {
  switch (action.type) {
    case TYPES.ERR_CREATE: {
      const { buttons, code, render, type } = action.payload;
      return { ...state, err: { buttons, code, render, type } };
    }
    case TYPES.ERR_RESET: {
      const { err } = initialState;
      return { ...state, err };
    }
    case TYPES.QUESTIONS_SET: {
      const questions = shuffle(action.payload.questions);
      const { err } = initialState;
      return { ...state, err, questions };
    }
    case TYPES.RESULTS_SET: {
      const { results } = action.payload;
      const { err } = initialState;
      return { ...state, err, results };
    }
    default: {
      return { ...state };
    }
  }
};

export const AppContext = React.createContext(initialState);

export function AppProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <AppContext.Provider value={{ dispatch, state }}>
      {props.children}
    </AppContext.Provider>
  );
}
