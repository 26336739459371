// @ts-check
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { InfoButton } from './info-button';
import { InfoSection } from './info-section';
import { MenuButton } from './menu-button';
import { NavBar } from './nav-bar';

const styles = StyleSheet.create({
  text: {
    fontFamily: 'Roboto',
    fontSize: 16,
    margin: 8,
  },
});

function InfoSupport(props) {
  return (
    <View>
      <Text style={styles.text}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vitae
        nisi dolor. Etiam mollis dui nulla, accumsan lobortis sem mattis sit
        amet. Quisque vulputate aliquam tempor. Donec sed gravida orci, a
        lacinia nunc. Quisque lobortis turpis eu diam placerat, fringilla
        viverra velit elementum. Quisque eu accumsan enim. Integer finibus justo
        ligula, in mattis dolor aliquam eget. Suspendisse ut augue non elit
        interdum euismod. Morbi in metus sit amet leo volutpat dictum a non
        felis. Sed congue nisl a neque mattis sodales.
      </Text>
      <Text style={styles.text}>
        Ut a blandit diam. Vivamus turpis ligula, dignissim non turpis in,
        finibus cursus tellus. Nam lacus libero, eleifend eget facilisis eget,
        vestibulum et turpis. Duis et purus commodo, finibus turpis quis,
        commodo quam. Donec pretium, lectus ut viverra mollis, lorem mauris
        ultricies lacus, ac pulvinar nibh lacus mattis nisi. Lorem ipsum dolor
        sit amet, consectetur adipiscing elit. Nulla hendrerit lacus leo, eget
        vulputate orci malesuada nec. Praesent vestibulum lorem ut dui tempor
        vulputate. Sed odio enim, eleifend sed ante eu, luctus tincidunt arcu.
        Sed gravida lorem in felis iaculis, vitae tristique mi gravida. Vivamus
        pulvinar odio nunc, et dictum lectus varius non. Mauris faucibus elit et
        dapibus sollicitudin. Mauris dolor leo, bibendum quis iaculis at,
        lobortis aliquam sapien.
      </Text>
    </View>
  );
}

function InfoTerms(props) {
  return (
    <View>
      <Text style={styles.text}>
        Duis vel est tellus. Fusce viverra mi arcu, vel auctor velit rutrum et.
        Praesent malesuada, velit id vestibulum blandit, nunc massa cursus nibh,
        nec hendrerit risus felis id mauris. Fusce rhoncus ut enim eget
        hendrerit. Donec scelerisque pharetra rutrum. Nam vel erat ac justo
        interdum ultricies. Duis convallis dolor eu lacus molestie consectetur.
        Ut faucibus molestie tristique. Vivamus sollicitudin mi odio, ut finibus
        leo fermentum sed. Nullam rutrum faucibus erat, sit amet aliquam lectus
        vehicula ut. Maecenas neque risus, posuere non vehicula eu, venenatis
        sed nisl.
      </Text>
      <Text style={styles.text}>
        Sed pellentesque placerat lacinia. Mauris semper augue a magna porta
        dignissim. Aliquam feugiat porttitor est, at mollis lacus venenatis
        facilisis. Curabitur ut sagittis libero, at bibendum mi. Aliquam et
        vehicula urna. Morbi laoreet neque ligula, quis dignissim tortor commodo
        sed. Mauris velit nisl, facilisis sit amet orci eu, elementum malesuada
        mi. Curabitur et nulla tortor. Maecenas a finibus nibh.
      </Text>
    </View>
  );
}

function InfoPrivacy(props) {
  return (
    <View>
      <Text style={styles.text}>
        Donec varius libero nec elit blandit, at porttitor felis tempor. Integer
        convallis accumsan libero vel ornare. Integer at nulla in odio convallis
        laoreet et commodo lectus. Mauris scelerisque pellentesque lectus, eu
        eleifend dolor dapibus in. Vivamus interdum dui at libero scelerisque,
        quis convallis mi mattis. Phasellus tristique odio ac bibendum
        dignissim. Suspendisse volutpat finibus augue, sed volutpat ante
        ullamcorper quis. Class aptent taciti sociosqu ad litora torquent per
        conubia nostra, per inceptos himenaeos. Nullam sodales lacinia
        tincidunt. Morbi non enim volutpat orci sodales efficitur eu non magna.
        Vivamus velit nunc, pulvinar eu dictum eget, efficitur eget tellus.
        Proin auctor ante velit, ac pharetra sapien dictum quis.
      </Text>
      <Text style={styles.text}>
        Pellentesque rhoncus luctus accumsan. Aliquam blandit, nisl ut
        condimentum molestie, arcu enim bibendum sem, vel egestas justo nisl at
        massa. Aliquam erat volutpat. Praesent ornare sollicitudin arcu quis
        posuere. Integer sed odio metus. Nullam cursus pellentesque orci, a
        consectetur lorem ornare sed. Etiam rutrum augue et ante suscipit, non
        ultricies leo porttitor. Mauris maximus libero eget augue cursus, in
        vehicula nibh luctus. Maecenas sagittis sapien turpis, eget auctor
        lectus congue ac. Aliquam vulputate, lacus id volutpat tempor, lacus
        nulla varius lacus, a egestas ipsum elit eget arcu.
      </Text>
    </View>
  );
}

export function ScreenSupport(props) {
  // State
  const [selected, setSelected] = React.useState('support');

  const handlePress = select => () => {
    setSelected(select);
  };

  const renderInfo = option => {
    const type = option.type.toLowerCase();
    switch (type) {
      case 'terms':
        return <InfoTerms />;
      case 'privacy':
        return <InfoPrivacy />;
      default:
        return <InfoSupport />;
    }
  };

  const options = [
    {
      title: 'support',
      type: 'support',
    },
    {
      title: 'terms',
      type: 'terms',
    },
    {
      title: 'privacy',
      type: 'privacy',
    },
  ];

  return (
    <View style={{ flex: 1 }}>
      <NavBar renderLeft={<MenuButton />} title="Support" />

      <View style={{ flex: 1, paddingHorizontal: 8 }}>
        {options.map(option => {
          const { title } = option;
          if (title === selected) {
            return (
              <InfoSection
                key={title}
                option={option}
                renderInfo={renderInfo}
              />
            );
          } else {
            return (
              <InfoButton
                handlePress={handlePress}
                key={title}
                option={option}
              />
            );
          }
        })}
      </View>
    </View>
  );
}

ScreenSupport.propTypes = {};
