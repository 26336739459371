import { transparentize } from 'polished';
import PropTypes from 'prop-types';
import React from 'react';
import { ProgressBar } from 'react-native';
import { config, Spring } from 'react-spring/renderprops';

export function ProgressIndicator(props) {
  const color = props.color || '#fff';

  return (
    <Spring
      config={config.slow}
      from={{ progress: 0 }}
      to={{ progress: props.progress }}
    >
      {values => (
        <ProgressBar
          color={color}
          progress={values.progress}
          style={{ borderRadius: 4, height: 4 }}
          trackColor={transparentize(0.7, color)}
        />
      )}
    </Spring>
  );
}

ProgressIndicator.propTypes = {
  color: PropTypes.string,
  progress: PropTypes.number.isRequired,
};
