// @ts-check
import PropTypes from 'prop-types';
import React from 'react';
import { View } from 'react-native';
import { AppContext, TYPES } from '../contexts/app-context';
import { validCode } from '../utils/valid-code';
import { Assessment } from './assessment';
import { AssessmentFetch } from './assessment-fetch';
import { AssessmentSubmit } from './assessment-submit';

export function ScreenAssessment(props) {
  // Context
  const context = React.useContext(AppContext);
  // Props
  const { history, match } = props;
  const { code } = match.params;
  // State
  const [readyToSubmit, setReadyToSubmit] = React.useState(false);
  // Variables
  const { isValidCode } = validCode({ code });

  if (!isValidCode) {
    if (!context.state.err.render) {
      context.dispatch({
        payload: {
          buttons: [
            {
              onPress: () => {
                history.push('/menu');
                context.dispatch({ type: TYPES.ERR_RESET });
              },
              text: 'Try again',
            },
          ],
          code,
          render: true,
          type: 'validation',
        },
        type: TYPES.ERR_CREATE,
      });
    }
    return <View />;
  } else if (readyToSubmit) {
    return <AssessmentSubmit code={code} history={history} />;
  } else if (context.state.questions.length) {
    return <Assessment setReadyToSubmit={setReadyToSubmit} />;
  } else {
    return <AssessmentFetch code={code} history={history} />;
  }
}

ScreenAssessment.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};
