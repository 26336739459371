// @ts-check
import { captureError } from './capture-error';

/**
 * @typedef {Object} Values
 * @property {Response} response
 * @property {Object} results
 *
 * @returns {Promise<Values>}
 */
export async function getMerchantDetails() {
  const input = `${process.env.REACT_APP_RESOURCE_URL}/anet/merchant-details`;

  try {
    const response = await fetch(input, {
      headers: { 'Content-Type': 'application/json' },
    });
    const results = await response.json();
    return Promise.resolve({ response, results });
  } catch (error) {
    captureError({ data: { error, input } });
    throw new Error(error);
  }
}
