// @ts-check
import PropTypes from 'prop-types';
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

const styles = StyleSheet.create({
  section: {
    flexDirection: 'row',
    marginBottom: 16,
  },
  sectionBorder: {
    backgroundColor: '#7A8FFF',
    borderRadius: 2,
    width: 4,
  },
  sectionData: {
    fontFamily: 'Roboto',
    fontSize: 16,
    lineHeight: 20,
  },
  sectionTitle: {
    fontFamily: 'Roboto',
    fontSize: 20,
    fontWeight: 'bold',
  },
  sectionView: {
    flex: 1,
    paddingLeft: 8,
  },
});

export function InfoAnimal(props) {
  // Props
  const { data } = props;

  return (
    <View>
      <View style={styles.section}>
        <View style={styles.sectionBorder} />

        <View style={styles.sectionView}>
          <Text style={styles.sectionTitle}>Traits</Text>
          <Text style={styles.sectionData}>{data.traits}</Text>
        </View>
      </View>

      <View style={styles.section}>
        <View style={styles.sectionBorder} />

        <View style={styles.sectionView}>
          <Text style={styles.sectionTitle}>Challenges</Text>
          <Text style={styles.sectionData}>{data.challenges}</Text>
        </View>
      </View>

      <View style={styles.section}>
        <View style={styles.sectionBorder} />

        <View style={styles.sectionView}>
          <Text style={styles.sectionTitle}>Under stress they may appear</Text>
          {data.underStressAppear.map(stressor => (
            <Text key={stressor} style={styles.sectionData}>
              - {stressor}
            </Text>
          ))}
        </View>
      </View>

      <View style={styles.section}>
        <View style={styles.sectionBorder} />

        <View style={styles.sectionView}>
          <Text style={styles.sectionTitle}>Favorite quote from a movie</Text>
          <View style={{ padding: 4 }}>
            <Text style={{ fontSize: 18, fontStyle: 'italic', padding: 4 }}>
              "{data.favoriteQuoteFromMovie.quote}"
            </Text>
            <Text style={{ fontFamily: 'Roboto' }}>
              {data.favoriteQuoteFromMovie.reference}
            </Text>
          </View>
        </View>
      </View>

      <View style={styles.section}>
        <View style={styles.sectionBorder} />

        <View style={styles.sectionView}>
          <Text style={styles.sectionTitle}>Core need</Text>
          <Text style={styles.sectionData}>{data.coreNeed}</Text>
        </View>
      </View>

      <View style={styles.section}>
        <View style={styles.sectionBorder} />

        <View style={styles.sectionView}>
          <Text style={styles.sectionTitle}>Validating the core need</Text>
          <Text style={styles.sectionData}>{data.validatingCoreNeed}</Text>
        </View>
      </View>

      <View style={styles.section}>
        <View style={styles.sectionBorder} />

        <View style={styles.sectionView}>
          <Text style={styles.sectionTitle}>Relationship with other types</Text>
          {data.relationships.map(relationship => {
            return (
              <Text key={relationship.type} style={{ marginVertical: 8 }}>
                <Text style={{ fontWeight: 'bold', marginRight: 8 }}>
                  {relationship.type.toUpperCase()}
                </Text>
                <Text style={styles.sectionData}>
                  {relationship.relationship}
                </Text>
              </Text>
            );
          })}
        </View>
      </View>

      <View style={styles.section}>
        <View style={styles.sectionBorder} />

        <View style={styles.sectionView}>
          <Text style={styles.sectionTitle}>Summary</Text>
          <Text style={styles.sectionData}>{data.summary}</Text>
        </View>
      </View>
    </View>
  );
}

InfoAnimal.propTypes = {
  data: PropTypes.object.isRequired,
};
