// @ts-check
import PropTypes from 'prop-types';
import React from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { captureError } from '../api/capture-error';
import { getCode } from '../api/get-code';
import { AppContext, TYPES } from '../contexts/app-context';
import { parseApiResults } from '../utils/parse-api-results';

const styles = StyleSheet.create({
  center: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
});

export function ResultsFetch(props) {
  // Context
  const { dispatch } = React.useContext(AppContext);
  // Props
  const { code, history } = props;

  React.useEffect(() => {
    const createMessage = type => {
      const status = type.toString().toLowerCase();

      if (status === 'not-found') {
        dispatch({
          payload: {
            buttons: [
              {
                onPress: () => {
                  history.push('/menu');
                  dispatch({ type: TYPES.ERR_RESET });
                },
                text: 'Try again',
              },
            ],
            code,
            render: true,
            type: 'not-found',
          },
          type: TYPES.ERR_CREATE,
        });
      } else if (status === 'unused') {
        dispatch({
          payload: {
            buttons: [
              {
                onPress: () => {
                  history.push('/menu');
                  dispatch({ type: TYPES.ERR_RESET });
                },
                text: 'Try again',
              },
              {
                onPress: () => {
                  history.push(`/tutorial?code=${code}`);
                  dispatch({ type: TYPES.ERR_RESET });
                },
                text: 'Begin assessment',
              },
            ],
            code,
            render: true,
            type: 'unused',
          },
          type: TYPES.ERR_CREATE,
        });
      } else {
        dispatch({
          payload: {
            buttons: [
              {
                onPress: () => {
                  history.push('/menu');
                  dispatch({ type: TYPES.ERR_RESET });
                },
                text: 'Return to menu',
              },
            ],
            code,
            render: true,
            type: 'error',
          },
          type: TYPES.ERR_CREATE,
        });
      }
    };

    async function fetchResults() {
      try {
        const { response, results } = await getCode({ code });

        if (response.status === 404) {
          // The assessment code was not found in the database
          createMessage('not-found');
        } else if (response.ok) {
          // details will have results or questions else parse will throw error
          const { details } = parseApiResults({ results });

          const hasResults = Object(details).hasOwnProperty(
            'assessmentResults'
          );
          const hasQuestions = Object(details).hasOwnProperty('questions');

          if (hasResults) {
            // The assessment code has been used
            dispatch({
              payload: { results: details.assessmentResults },
              type: TYPES.RESULTS_SET,
            });
          } else if (hasQuestions) {
            // The assessment code has not been used
            dispatch({
              payload: { questions: details.questions },
              type: TYPES.QUESTIONS_SET,
            });
            // For ResultsFetch we are expecting the code to have been used
            createMessage('unused');
          }
        } else {
          // response.ok === false
          let message = `${response.status} ${response.statusText}`;
          if (results['hydra:description']) {
            message = results['hydra:description'];
          }
          throw new Error(message);
        }
      } catch (error) {
        captureError({ data: { code, error } });
        createMessage('error');
      }
    }

    fetchResults();
  }, [code, dispatch, history]);

  return (
    <View style={styles.center}>
      <ActivityIndicator color="#16B9FF" size="large" />
    </View>
  );
}

ResultsFetch.propTypes = {
  code: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};
