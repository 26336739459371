// @ts-check
import { checkParams } from './check-params';

/**
 * @typedef {Object} Select
 * @property {number} [a]
 * @property {number} [b]
 * @property {number} [c]
 * @property {number} [d]
 *
 * @typedef {Object} Total
 * @property {number} a
 * @property {number} b
 * @property {number} c
 * @property {number} d
 *
 * @typedef {Object} Values
 * @property {Total} newTotals - { a: 111, b: 107, ...}
 *
 * @param {Object} params
 * @param {Select} params.selections - { a: 1, b: 2, ...}
 * @param {Total} params.totals - { a: 110, b: 105, ...}
 *
 * @returns {Values}
 */
export function calculateTotals(params) {
  checkParams({ functionName: 'calculateTotals', functionParams: params });
  const { selections, totals } = params;

  // New Totals to be returned
  const newTotals = { a: 0, b: 0, c: 0, d: 0 };

  Object.keys(selections).forEach(key => {
    newTotals[key] = totals[key] + selections[key];
  });

  return { newTotals };
}
