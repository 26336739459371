// @ts-check
import { checkParams } from '../utils/check-params';
import { captureError } from './capture-error';

/**
 * @typedef {Object} Values
 * @property {Response} response
 * @property {Object} results
 *
 * @param {Object} params
 * @param {string} params.code - The code being used for the assessment
 * @param {string} params.email - Email address of the user who took the assessment
 * @param {Object} params.results - { a: 111, b: 107, ...}
 *
 * @returns {Promise<Values>}
 */
export async function submitResults(params) {
  checkParams({ functionName: 'submitResults', functionParams: params });

  const input = `${process.env.REACT_APP_RESOURCE_URL}/assessment/${params.code}/submit`;

  try {
    const data = {
      assessmentResults: JSON.stringify(params.results),
      userEmail: params.email,
    };
    const response = await fetch(input, {
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json' },
      method: 'PUT',
    });
    const results = await response.json();
    return Promise.resolve({ response, results });
  } catch (error) {
    captureError({ data: { error, input, params } });
    throw new Error(error);
  }
}
