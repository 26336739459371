import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React from 'react';
import {
  ActivityIndicator,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { animated, useSpring } from 'react-spring/native';

const styles = StyleSheet.create({
  buttonPay: {
    backgroundColor: '#1935FB',
    borderRadius: 2,
    padding: 8,
  },
  buttonText: {
    alignSelf: 'center',
    color: '#fff',
    fontFamily: 'Roboto',
    fontSize: 18,
    fontWeight: 'bold',
  },
  center: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  container: {
    backgroundColor: 'rgba(255, 255, 255, 0.90)',
    padding: 8,
  },
});

const Animated = {
  View: animated(View),
};

export function PurchaseFormItemPay(props) {
  // Props
  const { handlePress, readyToPay, submitting } = props;
  // Variables
  const values = useSpring({
    from: { marginTop: 8, opacity: 0.1 },
    to: {
      marginTop: readyToPay ? 0 : 16,
      opacity: readyToPay ? 1 : 0.1,
    },
  });

  return (
    <Animated.View
      style={{ marginTop: values.marginTop, opacity: values.opacity }}
    >
      <View style={styles.container}>
        {submitting ? (
          <View style={styles.center}>
            <ActivityIndicator color="#16B9FF" />
          </View>
        ) : (
          <TouchableOpacity
            disabled={!readyToPay}
            style={styles.buttonPay}
            onPress={debounce(handlePress, 10)}
          >
            <Text style={styles.buttonText}>Pay now</Text>
          </TouchableOpacity>
        )}
      </View>
    </Animated.View>
  );
}

PurchaseFormItemPay.propTypes = {
  handlePress: PropTypes.func.isRequired,
  readyToPay: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};
