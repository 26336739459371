// @ts-check
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React from 'react';
import AlertCircle from 'react-feather/dist/icons/alert-circle';
import AlertTriangle from 'react-feather/dist/icons/alert-triangle';
import Frown from 'react-feather/dist/icons/frown';
import HelpCircle from 'react-feather/dist/icons/help-circle';
import Info from 'react-feather/dist/icons/info';
import PlayCircle from 'react-feather/dist/icons/play-circle';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { Spring } from 'react-spring/renderprops';
import theme from '../theme';

const styles = StyleSheet.create({
  button: {
    height: 40,
    justifyContent: 'center',
  },
  buttonText: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'bold',
  },
  buttons: {
    borderTopColor: '#eee',
    borderTopWidth: StyleSheet.hairlineWidth,
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  codeText: {
    fontFamily: 'Roboto Mono',
  },
  container: {
    alignItems: 'center',
    marginHorizontal: 8,
  },
  iconView: {
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: 50,
    borderWidth: 2,
    height: 44,
    justifyContent: 'center',
    position: 'absolute',
    width: 44,
    zIndex: 3,
  },
  message: {
    alignItems: 'center',
    backgroundColor: '#00000066',
    bottom: 0,
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 1,
  },
  messageContainer: {
    backgroundColor: '#fff',
    borderRadius: 8,
    marginTop: 22,
    paddingTop: 22,
  },
  messageText: {
    fontFamily: 'Roboto',
    fontSize: 16,
    padding: 8,
    textAlign: 'center',
  },
});

export function Message(props) {
  // Variables
  let borderColor = '#ff000044';
  let icon;
  let message;

  if (props.type === 'error') {
    icon = <Frown color="#f00" />;
    message = (
      <Text style={styles.messageText}>
        <Text>Sorry, something went wrong.</Text>
        {'\n'}
        <Text>
          We're working on it and we'll get it fixed as soon as we can.
        </Text>
      </Text>
    );
  } else if (props.type === 'payment-failure') {
    icon = <AlertTriangle color="#f00" />;
    message = (
      <Text style={styles.messageText}>
        <Text>There was an error processing your payment information.</Text>
        {'\n'}
        <Text>Please try again.</Text>
      </Text>
    );
  } else if (props.type === 'validation') {
    icon = <AlertCircle color="#f00" />;
    message = (
      <Text style={styles.messageText}>
        <Text>
          The code (<Text style={styles.codeText}>{props.code}</Text>) did not
          pass validation.
        </Text>
        {'\n'}
        <Text>Please check it for accuracy and try again.</Text>
      </Text>
    );
  } else if (props.type === 'not-found') {
    icon = <HelpCircle color="#f00" />;
    message = (
      <Text style={styles.messageText}>
        <Text>
          The code (<Text style={styles.codeText}>{props.code}</Text>) could not
          be found.
        </Text>
        {'\n'}
        <Text>Please check it for accuracy and try again.</Text>
      </Text>
    );
  } else if (props.type === 'expired') {
    borderColor = `${theme.colors.primary}44`;
    icon = <Info color={theme.colors.primary} />;
    message = (
      <Text style={styles.messageText}>
        <Text>
          The code (<Text style={styles.codeText}>{props.code}</Text>) has
          already been used.
        </Text>
        {'\n'}
        <Text>Would you like to see the results?</Text>
      </Text>
    );
  } else if (props.type === 'unused') {
    borderColor = `${theme.colors.secondary}44`;
    icon = <PlayCircle color={theme.colors.secondary} />;
    message = (
      <Text style={styles.messageText}>
        <Text>
          The code (<Text style={styles.codeText}>{props.code}</Text>) has not
          been used.
        </Text>
        {'\n'}
        <Text>Would you like to begin the assessment?</Text>
      </Text>
    );
  }

  return (
    <Spring
      from={{ opacity: 0, translateY: 40 }}
      to={{ opacity: 1, translateY: 0 }}
    >
      {values => {
        const { opacity, translateY } = values;
        return (
          <View style={[styles.message]}>
            <View
              style={[
                styles.container,
                { opacity, transform: [{ translateY }] },
              ]}
            >
              <View style={[styles.iconView, { borderColor }]}>{icon}</View>

              <View style={styles.messageContainer}>
                <View>{message}</View>

                <View style={styles.buttons}>
                  {props.buttons.map(button => {
                    return (
                      <TouchableOpacity
                        key={button.text}
                        style={styles.button}
                        onPress={debounce(button.onPress, 10)}
                      >
                        <Text style={styles.buttonText}>{button.text}</Text>
                      </TouchableOpacity>
                    );
                  })}
                </View>
              </View>
            </View>
          </View>
        );
      }}
    </Spring>
  );
}

Message.propTypes = {
  buttons: PropTypes.array.isRequired,
  code: PropTypes.string,
  type: PropTypes.oneOf([
    'error',
    'payment-failure',
    'validation',
    'not-found',
    'expired',
    'unused',
  ]).isRequired,
};
