// @ts-check
import PropTypes from 'prop-types';
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { ProgressButton } from './progress-button';
import { RankGuide } from './rank-guide';
import { Statement } from './statement';

const styles = StyleSheet.create({
  tutorialText: {
    color: '#fff',
    fontFamily: 'Roboto',
    fontSize: 24,
    textAlign: 'center',
  },
  tutorialView: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    paddingHorizontal: 20,
  },
});

export function TutorialStepTwo(props) {
  return (
    <View style={{ flex: 1 }}>
      <View style={styles.tutorialView}>
        <Text style={styles.tutorialText}>
          If you would like to change how you ranked a statement, press the
          number you selected again.
        </Text>
      </View>

      <View style={styles.tutorialView}>
        <Text style={styles.tutorialText}>
          Try unselecting the number below.
        </Text>
      </View>

      <View style={{ flex: 1 }}>
        <View style={{ paddingHorizontal: 10 }}>
          <RankGuide />
        </View>

        <Statement selected={Boolean(props.unselected)} text="Enthusiastic.">
          {[1, 2, 3, 4].map(value => {
            const selected = props.unselected === value;
            let disabled = false;
            if (Boolean(props.unselected) && props.unselected !== value) {
              disabled = true;
            }

            return (
              <ProgressButton
                disabled={disabled}
                key={value}
                selected={selected}
                text={value.toString()}
                onPress={props.statementUnselect}
              />
            );
          })}
        </Statement>
      </View>
    </View>
  );
}

TutorialStepTwo.propTypes = {
  statementUnselect: PropTypes.func.isRequired,
  unselected: PropTypes.number,
};
