import PropTypes from 'prop-types';
import React from 'react';
import { StyleSheet, Text, TextInput, View } from 'react-native';
import { animated, useSpring } from 'react-spring/native';

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgba(255, 255, 255, 0.90)',
    padding: 8,
  },
  input: {
    backgroundColor: '#fff',
    borderBottomColor: '#16B9FF',
    borderBottomStyle: 'solid',
    borderBottomWidth: 2,
    fontFamily: 'Roboto Mono',
    fontSize: 16,
    fontWeight: 'bold',
    height: 52,
    paddingLeft: 10,
  },
  title: {
    color: '#222',
    fontFamily: 'Roboto',
    fontSize: 10,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
});

const Animated = {
  View: animated(View),
};

export function PurchaseFormItemEmail(props) {
  // Props
  const { email, setEmail, validEmail } = props;
  // Variables
  const values = useSpring({
    from: { marginVertical: 16 },
    to: { marginVertical: validEmail ? 0 : 16 },
  });

  return (
    <Animated.View style={{ marginVertical: values.marginVertical }}>
      <View style={styles.container}>
        <Text style={styles.title}>Email Address</Text>

        <TextInput
          keyboardType="email-address"
          placeholder="example@mail.com"
          style={styles.input}
          value={email}
          onChangeText={text => setEmail(text)}
        />
      </View>
    </Animated.View>
  );
}

PurchaseFormItemEmail.propTypes = {
  email: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
  validEmail: PropTypes.bool.isRequired,
};
