// @ts-check
import PropTypes from 'prop-types';
import React from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { captureError } from '../api/capture-error';
import { getMerchantDetails } from '../api/get-merchant-details';
import { AppContext, TYPES } from '../contexts/app-context';
import { PurchaseFormSubmit } from './purchase-form-submit';

const styles = StyleSheet.create({
  center: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
});

export function PurchaseFormLoad(props) {
  // Context
  const context = React.useContext(AppContext);
  const { dispatch } = context;
  // Props
  const { history } = props;
  // State
  const [loading, setLoading] = React.useState(true);
  const [keyApi, setKeyApi] = React.useState('');
  const [keyPub, setKeyPub] = React.useState('');

  React.useEffect(() => {
    async function fetchDetails() {
      try {
        const { response, results } = await getMerchantDetails();

        if (response.ok) {
          const { name: apiLoginKey, publicClientKey } = results;

          if (apiLoginKey && publicClientKey) {
            setKeyApi(apiLoginKey);
            setKeyPub(publicClientKey);
            // When loading is set to false PurchaseFormSubmit will be rendered
            setLoading(false);
          } else {
            const message =
              'Response from server did not include name/apiLoginKey and publicClientKey';
            throw new Error(
              `${response.status} ${response.statusText}: ${message}`
            );
          }
        } else {
          // response.ok === false
          let message = `${response.status} ${response.statusText}`;
          if (results['hydra:description']) {
            message = results['hydra:description'];
          }
          throw new Error(message);
        }
      } catch (error) {
        captureError({ data: { error } });
        dispatch({
          payload: {
            buttons: [
              {
                onPress: () => {
                  history.push('/menu');
                  dispatch({ type: TYPES.ERR_RESET });
                },
                text: 'Return to menu',
              },
            ],
            render: true,
            type: 'error',
          },
          type: TYPES.ERR_CREATE,
        });
      }
    }

    fetchDetails();
  }, [dispatch, history]);

  if (loading) {
    return (
      <View style={styles.center}>
        <ActivityIndicator color="#16B9FF" size="large" />
      </View>
    );
  } else {
    return (
      <PurchaseFormSubmit
        apiLoginKey={keyApi}
        history={props.history}
        publicClientKey={keyPub}
        setCode={props.setCode}
        setViewing={props.setViewing}
      />
    );
  }
}

PurchaseFormLoad.propTypes = {
  history: PropTypes.object.isRequired,
  setCode: PropTypes.func.isRequired,
  setViewing: PropTypes.func.isRequired,
};
