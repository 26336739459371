// @ts-check
import { checkParams } from './check-params';

/**
 * @typedef {Object} Values
 * @property {Object} details
 * @property {Object} [details.assessmentResults] - { a: 98, b: 107, c: 112, d: 103 }
 * @property {Array} [details.questions]
 *
 * @param {Object} params
 * @param {Object} params.results - The json results from the api request
 *
 * @returns {Values}
 */
export function parseApiResults(params) {
  checkParams({ functionName: 'parseApiResults', functionParams: params });
  const { results } = params;

  // The details of the api results to be returned
  const details = {};

  // Check for assessment results
  const hasResults = Object(results).hasOwnProperty('assessmentResults');
  if (hasResults && results.assessmentResults !== null) {
    // Add the parsed assessment results to the details object
    details.assessmentResults = JSON.parse(results.assessmentResults);
  }

  // Check for asssessment questions
  const hasQuestions = Object(results).hasOwnProperty('questions');
  if (hasQuestions && results.questions.length) {
    details.questions = results.questions;
  }

  // If there are no results or questions throw an error
  if (!details.assessmentResults && !details.questions) {
    throw new Error(
      'Response from the server did not include assessmentResults or questions'
    );
  }

  return { details };
}
