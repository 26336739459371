import React from 'react';
import ArrowRight from 'react-feather/dist/icons/arrow-right';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { Link } from 'react-router-dom';
import { animated, config, useSpring } from 'react-spring/native';
import theme from '../theme';
import { HomePreview } from './home-preview';

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  buttonText: {
    color: '#fff',
    fontFamily: 'Roboto',
    fontSize: 28,
    fontWeight: 'bold',
    marginRight: 8,
  },
  circle: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: 200,
    position: 'absolute',
    zIndex: -1,
  },
  introSection: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    paddingHorizontal: 16,
  },
  introText: {
    color: '#fff',
    fontFamily: 'Roboto',
    fontSize: 18,
    lineHeight: 22,
    maxWidth: 530,
    textAlign: 'center',
  },
  screen: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  title: {
    color: '#fff',
    fontFamily: 'Roboto',
    fontSize: 48,
    fontWeight: 'bold',
    textShadowColor: theme.colors.textShadowColor,
    textShadowOffset: {
      height: 3,
      width: 0,
    },
    textShadowRadius: 2,
    textTransform: 'uppercase',
  },
  titleSection: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    paddingVertical: 40,
  },
});

const Animated = {
  Text: animated(Text),
  View: animated(View),
};

export function ScreenHome(props) {
  const values = useSpring({
    config: config.slow,
    from: { margin: 12, opacity: 0, size: 180 },
    to: { margin: 0, opacity: 1, size: 250 },
  });
  const { margin, opacity, size } = values;
  const textStyles = [styles.title, { margin, opacity }];
  const circleStyles = [styles.circle, { height: size, opacity, width: size }];

  return (
    <View style={styles.screen}>
      <View style={{ flex: 4 }}>
        <View style={styles.titleSection}>
          <Animated.Text style={textStyles}>The</Animated.Text>
          <Animated.Text style={textStyles}>Animal</Animated.Text>
          <Animated.Text style={textStyles}>Inventory</Animated.Text>

          <Animated.View style={circleStyles} />
        </View>
      </View>

      <View style={styles.introSection}>
        <Text style={styles.introText}>
          Gain understanding about how you perceive the world around you. Learn
          how to make the most of your unique traits.
        </Text>
      </View>

      <View style={{ alignItems: 'center', flex: 1, justifyContent: 'center' }}>
        <Link style={{ textDecorationLine: 'none' }} to="/menu">
          <TouchableOpacity accessible={false} style={styles.button}>
            <Text style={styles.buttonText}>Get started</Text>

            <ArrowRight color="#fff" size={32} />
          </TouchableOpacity>
        </Link>
      </View>

      <HomePreview />
    </View>
  );
}

ScreenHome.propTypes = {};
