// @ts-check
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

const styles = StyleSheet.create({
  guideText: {
    color: '#fff',
    fontFamily: 'Roboto',
    fontSize: 9,
    fontWeight: 'bold',
    letterSpacing: 0.7,
    textTransform: 'uppercase',
  },
  guideView: {
    flexDirection: 'row',
    paddingVertical: 4,
  },
});

export function RankGuide(props) {
  return (
    <View style={styles.guideView}>
      <Text style={styles.guideText}>1 = Least Reflective</Text>
      <View style={{ flex: 1 }} />
      <Text style={styles.guideText}>Most Reflective = 4</Text>
    </View>
  );
}

RankGuide.propTypes = {};
