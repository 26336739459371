// @ts-check
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { ProgressButton } from './progress-button';
import { RankGuide } from './rank-guide';
import { Statement } from './statement';

const styles = StyleSheet.create({
  frame: {
    backgroundColor: '#111',
    borderTopLeftRadius: 40,
    borderTopRightRadius: 40,
    marginLeft: 16,
    marginRight: 16,
    maxWidth: 360,
    paddingHorizontal: 16,
    paddingTop: 18,
  },
  header: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  instructionText: {
    color: '#fff',
    fontFamily: 'Roboto',
    fontSize: 12,
    padding: 8,
    textAlign: 'center',
  },
  notch: {
    backgroundColor: '#111',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    height: 16,
    marginBottom: 8,
    width: 128,
  },
  punch: {
    backgroundColor: '#111',
    borderRadius: 16,
    height: 20,
    marginRight: 24,
    marginTop: 8,
    width: 48,
  },
  screen: {
    backgroundColor: '#2322ED',
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
  },
});

export function HomePreview(props) {
  // State
  const [selected, setSelected] = React.useState(0);
  // Variables
  const { userAgent } = window.navigator;
  const isAndroid = userAgent.toLowerCase().includes('android');

  const selectNumber = number => () => {
    if (Boolean(selected)) {
      setSelected(0);
    } else {
      setSelected(number);
    }
  };

  return (
    <View style={styles.frame}>
      <View style={styles.screen}>
        {isAndroid ? (
          <View style={styles.header}>
            <View style={{ flex: 1 }} />
            <View style={styles.punch} />
          </View>
        ) : (
          <View style={styles.header}>
            <View style={{ flex: 1 }} />
            <View style={styles.notch} />
            <View style={{ flex: 1 }} />
          </View>
        )}

        <View style={{ height: 160 }}>
          <Text style={styles.instructionText}>
            Assign the following statements a value according to how well you
            believe the statement reflects upon you.
          </Text>

          <View style={{ paddingHorizontal: 10 }}>
            <RankGuide />
          </View>

          <Statement selected={Boolean(selected)} text="I am risk-taking.">
            {[1, 2, 3, 4].map(value => {
              const isSelected = selected === value;
              let disabled = false;
              if (Boolean(selected) && selected !== value) {
                disabled = true;
              }

              return (
                <ProgressButton
                  disabled={disabled}
                  key={value}
                  selected={isSelected}
                  text={value.toString()}
                  onPress={selectNumber(value)}
                />
              );
            })}
          </Statement>
        </View>
      </View>
    </View>
  );
}

HomePreview.propTypes = {};
