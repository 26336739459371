// @ts-check
import React from 'react';
import { AppContext } from '../contexts/app-context';
import { Message } from './message';

export function MessageDisplay(props) {
  // Context
  const context = React.useContext(AppContext);
  const { buttons, code, render, type } = context.state.err;

  if (render) {
    return (
      <Message
        buttons={buttons}
        code={code}
        type={type.toString().toLowerCase()}
      />
    );
  } else {
    return null;
  }
}

MessageDisplay.propTypes = {};
