// @ts-check
import PropTypes from 'prop-types';
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

const styles = StyleSheet.create({
  section: {
    flexDirection: 'row',
    marginVertical: 8,
  },
  sectionBorder: {
    backgroundColor: '#7A8FFF',
    borderRadius: 2,
    width: 4,
  },
  sectionData: {
    fontFamily: 'Roboto',
    fontSize: 16,
    lineHeight: 20,
  },
  sectionTitle: {
    fontFamily: 'Roboto',
    fontSize: 20,
    fontWeight: 'bold',
  },
  sectionView: {
    flex: 1,
    paddingLeft: 8,
  },
});

export function InfoTrait(props) {
  // Props
  const { data, title } = props;

  if (title === 'UNASSIGNED') {
    return (
      <View style={{ paddingLeft: 6 }}>
        <Text style={{ fontFamily: 'Roboto' }}>
          The scores for the different animals were so close that a trait could
          not be assigned.
        </Text>
      </View>
    );
  } else {
    return (
      <View>
        <View style={styles.section}>
          <View style={styles.sectionBorder} />

          <View style={styles.sectionView}>
            <Text style={styles.sectionData}>{data.intro}</Text>
          </View>
        </View>

        <View style={styles.section}>
          <View style={styles.sectionBorder} />

          <View style={styles.sectionView}>
            <Text style={styles.sectionTitle}>Strength</Text>
            <Text style={styles.sectionData}>{data.strength}</Text>
          </View>
        </View>

        <View style={styles.section}>
          <View style={styles.sectionBorder} />

          <View style={styles.sectionView}>
            <Text style={styles.sectionTitle}>Weakness</Text>
            <Text style={styles.sectionData}>{data.weakness}</Text>
          </View>
        </View>
      </View>
    );
  }
}

InfoTrait.propTypes = {
  data: PropTypes.shape({
    intro: PropTypes.string.isRequired,
    strength: PropTypes.string.isRequired,
    weakness: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
};
