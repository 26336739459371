import PropTypes from 'prop-types';
import React from 'react';
import ArrowLeft from 'react-feather/dist/icons/arrow-left';
import ChevronRight from 'react-feather/dist/icons/chevron-right';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { Link } from 'react-router-dom';
import { animated, useSpring } from 'react-spring/native';
import { CodeInput } from './code-input';
import { NavBar } from './nav-bar';

const styles = StyleSheet.create({
  back: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  button: {
    alignItems: 'center',
    alignSelf: 'stretch',
    backgroundColor: '#ffffffdd',
    borderRadius: 8,
    flexDirection: 'row',
    padding: 8,
  },
  buttonText: {
    fontFamily: 'Roboto',
    fontSize: 12,
  },
  buttonTitle: {
    fontFamily: 'Roboto',
    fontSize: 24,
    fontWeight: 'bold',
  },
  container: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  screen: {
    flex: 1,
  },
  supportButton: {
    borderColor: '#ffffff77',
    borderRadius: 4,
    borderWidth: StyleSheet.hairlineWidth,
    padding: 4,
  },
  supportText: {
    color: '#ffffffaa',
    fontFamily: 'Roboto',
    fontSize: 12,
    marginHorizontal: 4,
  },
  supportView: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 8,
  },
});

const Animated = {
  View: animated(View),
};

export function ScreenMenu(props) {
  // Props
  const { history } = props;
  // State
  const [inputFocused, setInputFocused] = React.useState(false);

  const values = useSpring({
    from: { marginTop: 64, mt: 24, opacity: 0 },
    to: { marginTop: 0, mt: 8, opacity: 1 },
  });
  const { marginTop, mt, opacity } = values;

  return (
    <View style={styles.screen}>
      <NavBar
        renderLeft={
          <Link style={{ textDecorationLine: 'none' }} to="/">
            <TouchableOpacity accessible={false} style={{ paddingVertical: 6 }}>
              <ArrowLeft color="#fff" size={20} />
            </TouchableOpacity>
          </Link>
        }
        title="Menu"
      />

      <View style={styles.container}>
        <Animated.View style={{ marginTop, opacity }}>
          <CodeInput history={history} setInputFocused={setInputFocused} />

          <Animated.View style={{ marginTop: mt, paddingHorizontal: 8 }}>
            <Link style={{ textDecorationLine: 'none' }} to="/purchase">
              <TouchableOpacity accessible={false} style={styles.button}>
                <View style={{ flex: 1 }}>
                  <Text style={styles.buttonTitle}>Purchase a code</Text>

                  {!inputFocused && (
                    <Text style={styles.buttonText}>
                      To take the assessment you will need a code.
                    </Text>
                  )}
                </View>

                <ChevronRight />
              </TouchableOpacity>
            </Link>
          </Animated.View>

          <Animated.View style={{ marginTop: mt, paddingHorizontal: 8 }}>
            <Link style={{ textDecorationLine: 'none' }} to="/animals">
              <TouchableOpacity accessible={false} style={styles.button}>
                <View style={{ flex: 1 }}>
                  <Text style={styles.buttonTitle}>
                    Learn about the animals
                  </Text>

                  {!inputFocused && (
                    <Text style={styles.buttonText}>
                      Find out how the animals relate to personality types.
                    </Text>
                  )}
                </View>

                <ChevronRight />
              </TouchableOpacity>
            </Link>
          </Animated.View>
        </Animated.View>
      </View>

      {!inputFocused && (
        <View style={styles.supportView}>
          {/* <TouchableOpacity
            style={styles.supportButton}
            onPress={() => history.push('/support')}
          >
            <View style={{ flexDirection: 'row' }}>
              <Text style={styles.supportText}>Support</Text>
              <Text style={styles.supportText}>|</Text>
              <Text style={styles.supportText}>Terms of Use</Text>
              <Text style={styles.supportText}>|</Text>
              <Text style={styles.supportText}>Privacy Policy</Text>
            </View>
          </TouchableOpacity> */}
        </View>
      )}
    </View>
  );
}

ScreenMenu.propTypes = {
  history: PropTypes.object.isRequired,
};
