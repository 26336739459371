// @ts-check
import PropTypes from 'prop-types';
import React from 'react';
import {
  ActivityIndicator,
  StyleSheet,
  Text,
  TextInput,
  View,
} from 'react-native';
import isEmail from 'validator/lib/isEmail';
import { captureError } from '../api/capture-error';
import { submitResults } from '../api/submit-results';
import { AppContext, TYPES } from '../contexts/app-context';
import theme from '../theme';
import { NavBar } from './nav-bar';
import { ProgressButton } from './progress-button';

const styles = StyleSheet.create({
  container: {
    alignSelf: 'stretch',
    backgroundColor: '#fff',
    borderRadius: 8,
    marginHorizontal: 8,
    padding: 8,
  },
  instructionText: {
    fontFamily: 'Roboto',
    fontSize: 18,
  },
  screen: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  textInput: {
    backgroundColor: '#fafafa',
    borderBottomColor: theme.colors.secondary,
    borderBottomWidth: 2,
    fontFamily: 'Roboto Mono',
    fontSize: 16,
    fontWeight: 'bold',
    height: 44,
    marginVertical: 8,
    paddingLeft: 8,
  },
  titleText: {
    fontFamily: 'Roboto',
    fontSize: 36,
    fontWeight: 'bold',
  },
});

export function AssessmentSubmit(props) {
  // Context
  const context = React.useContext(AppContext);
  const { results: assessmentResults } = context.state;
  // Props
  const { code, history } = props;
  // State
  const [email, setEmail] = React.useState('');
  const [waiting, setWaiting] = React.useState(false);
  // Variables
  const validEmail = isEmail(email);

  const handlePress = async () => {
    try {
      setWaiting(true);
      const { response, results } = await submitResults({
        code,
        email,
        results: assessmentResults,
      });
      if (response.ok) {
        // Assessment results have been saved to the db
        history.push(`/results/${code}`);
      } else {
        // response.ok === false
        let message = `${response.status} ${response.statusText}`;
        if (results['hydra:description']) {
          message = results['hydra:description'];
        }
        throw new Error(message);
      }
    } catch (error) {
      captureError({
        data: { code, email, error, results: assessmentResults },
      });
      context.dispatch({
        payload: {
          buttons: [
            {
              onPress: () => {
                history.push('/menu');
                context.dispatch({ type: TYPES.ERR_RESET });
              },
              text: 'Return to menu',
            },
          ],
          render: true,
          type: 'error',
        },
        type: TYPES.ERR_CREATE,
      });
      setWaiting(false);
    }
  };

  return (
    <View style={{ flex: 1 }}>
      <NavBar title="Finish" />

      <View style={styles.screen}>
        <View style={styles.container}>
          <View>
            <Text style={styles.titleText}>Your email</Text>

            <Text style={styles.instructionText}>
              This email address will be associated with the results of the
              assessment.
            </Text>
          </View>

          <View>
            <TextInput
              keyboardType="email-address"
              placeholder="example@mail.com"
              style={styles.textInput}
              value={email}
              onChangeText={text => setEmail(text)}
            />

            {waiting ? (
              <ActivityIndicator
                color="#16B9FF"
                style={{ alignSelf: 'flex-end' }}
              />
            ) : (
              <ProgressButton
                buttonStyles={{
                  alignSelf: 'flex-end',
                  backgroundColor: !validEmail
                    ? `${theme.colors.primary}77`
                    : theme.colors.primary,
                }}
                disabled={!validEmail}
                text="See results"
                textStyles={{ color: '#fff' }}
                onPress={handlePress}
              />
            )}
          </View>
        </View>
      </View>
    </View>
  );
}

AssessmentSubmit.propTypes = {
  code: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};
