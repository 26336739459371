// @ts-check
import React from 'react';
import { useWindowDimensions, View } from 'react-native';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { AppProvider } from '../contexts/app-context';
import { MessageDisplay } from './message-display';
import { ScreenAnimals } from './screen-animals';
import { ScreenAssessment } from './screen-assessment';
import { ScreenHome } from './screen-home';
import { ScreenMenu } from './screen-menu';
import { ScreenPurchase } from './screen-purchase';
import { ScreenResults } from './screen-results';
import { ScreenSupport } from './screen-support';
import { ScreenTutorial } from './screen-tutorial';

export function App() {
  // Variables
  const { height, width } = useWindowDimensions();

  return (
    <AppProvider>
      <React.Fragment>
        <Router>
          <View style={{ alignItems: 'center', height, width }}>
            <View style={{ flex: 1, maxWidth: 800, width }}>
              <Switch>
                <Route component={ScreenHome} exact path="/" />
                <Route component={ScreenAnimals} path="/animals" />
                <Route component={ScreenAssessment} path="/assessment/:code" />
                <Route component={ScreenMenu} path="/menu" />
                <Route component={ScreenPurchase} path="/purchase" />
                <Route component={ScreenResults} path="/results/:code" />
                <Route component={ScreenSupport} path="/support" />
                <Route component={ScreenTutorial} path="/tutorial" />
                <Redirect to="/" />
              </Switch>
            </View>
          </View>
        </Router>

        <MessageDisplay />
      </React.Fragment>
    </AppProvider>
  );
}

App.propTypes = {};
