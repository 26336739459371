// @ts-check
import PropTypes from 'prop-types';
import React from 'react';
import {
  ActivityIndicator,
  StyleSheet,
  Text,
  TextInput,
  View,
} from 'react-native';
import { captureError } from '../api/capture-error';
import { getCode } from '../api/get-code';
import { AppContext, TYPES } from '../contexts/app-context';
import theme from '../theme';
import { parseApiResults } from '../utils/parse-api-results';
import { validCode } from '../utils/valid-code';
import { ProgressButton } from './progress-button';

const styles = StyleSheet.create({
  container: {
    alignSelf: 'stretch',
    backgroundColor: '#fff',
    borderRadius: 8,
    marginHorizontal: 8,
    padding: 8,
  },
  instructionText: {
    fontFamily: 'Roboto',
    fontSize: 18,
  },
  textInput: {
    backgroundColor: '#fafafa',
    borderBottomColor: theme.colors.secondary,
    borderBottomWidth: 2,
    fontFamily: 'Roboto Mono',
    fontSize: 24,
    fontWeight: 'bold',
    height: 44,
    marginVertical: 8,
    paddingLeft: 8,
  },
  titleText: {
    fontFamily: 'Roboto',
    fontSize: 36,
    fontWeight: 'bold',
  },
});

export function CodeInput(props) {
  // Context
  const context = React.useContext(AppContext);
  const { dispatch } = context;
  // Props
  const { history, setInputFocused } = props;
  // State
  const [waiting, setWaiting] = React.useState(false);
  const [code, setCode] = React.useState('');
  // Variables
  const { isValidCode } = validCode({ code });

  const createMessage = type => {
    const status = type.toString().toLowerCase();

    if (status === 'not-found') {
      context.dispatch({
        payload: {
          buttons: [
            {
              onPress: () => {
                dispatch({ type: TYPES.ERR_RESET });
              },
              text: 'Try again',
            },
          ],
          code,
          render: true,
          type: 'not-found',
        },
        type: TYPES.ERR_CREATE,
      });
    } else {
      dispatch({
        payload: {
          buttons: [
            {
              onPress: () => {
                dispatch({ type: TYPES.ERR_RESET });
              },
              text: 'Close',
            },
          ],
          code,
          render: true,
          type: 'error',
        },
        type: TYPES.ERR_CREATE,
      });
    }
  };

  const handlePress = async () => {
    try {
      setWaiting(true);
      const { response, results } = await getCode({ code });

      if (response.status === 404) {
        // The assessment code was not found in the database
        createMessage('not-found');
        setWaiting(false);
      } else if (response.ok) {
        // details will have results or questions else parse will throw error
        const { details } = parseApiResults({ results });

        const hasResults = Object(details).hasOwnProperty('assessmentResults');
        const hasQuestions = Object(details).hasOwnProperty('questions');

        if (hasResults) {
          // The assessment code has been used
          dispatch({
            payload: { results: details.assessmentResults },
            type: TYPES.RESULTS_SET,
          });
          history.push(`/results/${code}`);
        } else if (hasQuestions) {
          // The assessment code has not been used
          dispatch({
            payload: { questions: details.questions },
            type: TYPES.QUESTIONS_SET,
          });
          history.push(`/tutorial?code=${code}`);
        }
      } else {
        // response.ok === false
        let message = `${response.status} ${response.statusText}`;
        if (results['hydra:description']) {
          message = results['hydra:description'];
        }
        throw new Error(message);
      }
    } catch (error) {
      captureError({ data: { code, error } });
      createMessage('error');
      setWaiting(false);
    }
  };

  return (
    <View style={styles.container}>
      <View>
        <Text style={styles.titleText}>Your code</Text>

        <Text style={styles.instructionText}>
          Input your code here to begin the assessment or view your results.
        </Text>
      </View>

      <View>
        <TextInput
          placeholder="code"
          style={styles.textInput}
          value={code}
          onBlur={() => setInputFocused(false)}
          onChangeText={text => setCode(text)}
          onFocus={() => setInputFocused(true)}
        />

        {waiting ? (
          <ActivityIndicator
            color="#16B9FF"
            style={{ alignSelf: 'flex-end' }}
          />
        ) : (
          <ProgressButton
            buttonStyles={{
              alignSelf: 'flex-end',
              backgroundColor: !isValidCode
                ? `${theme.colors.primary}77`
                : theme.colors.primary,
            }}
            disabled={!isValidCode}
            text="next"
            textStyles={{ color: '#fff' }}
            onPress={handlePress}
          />
        )}
      </View>
    </View>
  );
}

CodeInput.propTypes = {
  history: PropTypes.object.isRequired,
  setInputFocused: PropTypes.func.isRequired,
};
