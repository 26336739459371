import debounce from 'lodash/debounce';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import ChevronRight from 'react-feather/dist/icons/chevron-right';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { animated, useSpring } from 'react-spring/native';
import theme from '../theme';

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: 4,
    flexDirection: 'row',
    marginBottom: 8,
    paddingHorizontal: 8,
    paddingTop: 8,
  },
  buttonText: {
    color: '#222',
    fontFamily: 'Roboto',
    fontSize: 18,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  significance: {
    color: theme.colors.primary,
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: 'bold',
    marginLeft: 8,
    textTransform: 'uppercase',
  },
});

const Animated = {
  Text: animated(Text),
  TouchableOpacity: animated(TouchableOpacity),
  View: animated(View),
};

export function InfoButton(props) {
  // Props
  const { title } = props.option;
  // Variables
  const significance = get(props.option, 'significance', '');

  const values = useSpring({
    from: { paddingBottom: 24, paddingLeft: 8, rotate: 90 },
    to: { paddingBottom: 8, paddingLeft: 0, rotate: 0 },
  });
  const { paddingBottom, paddingLeft, rotate } = values;
  const transform = rotate.interpolate(r => [{ rotate: `${r}deg` }]);

  return (
    <Animated.TouchableOpacity
      style={[styles.button, { paddingBottom }]}
      onPress={debounce(props.handlePress(title), 10)}
    >
      <Animated.Text style={[styles.buttonText, { paddingLeft }]}>
        {title}
      </Animated.Text>

      {Boolean(significance) && (
        <Text style={styles.significance}>{significance}</Text>
      )}

      <View style={{ flex: 1 }} />

      <Animated.View style={{ transform }}>
        <ChevronRight color="#999" size={18} />
      </Animated.View>
    </Animated.TouchableOpacity>
  );
}

InfoButton.propTypes = {
  handlePress: PropTypes.func.isRequired,
  option: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }),
};
