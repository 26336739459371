// @ts-check
import { checkParams } from '../utils/check-params';
import { captureError } from './capture-error';

/**
 * @typedef {Object} Values
 * @property {Response} response
 * @property {Object} results
 *
 * @param {Object} params
 * @param {string} params.code - The code being used for the assessment
 *
 * @returns {Promise<Values>}
 */
export async function getCode(params) {
  checkParams({ functionName: 'getCode', functionParams: params });

  const input = `${process.env.REACT_APP_RESOURCE_URL}/assessment/${params.code}`;

  try {
    const response = await fetch(input, {
      headers: { 'Content-Type': 'application/json' },
    });
    const results = await response.json();

    return Promise.resolve({ response, results });
  } catch (error) {
    captureError({ data: { error, input, params } });
    throw new Error(error);
  }
}
